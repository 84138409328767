import { CustomerTokenPurposeEnum, Timeframe } from 'lib/types/types';
import { TimeframeUnit } from '__generated__/graphql';
import { Chart, DataPoint, Dataset } from 'pages/api/graphql/schema/chart/Chart';
import { faker } from '@faker-js/faker';

export const PERCENTAGE_MAX_DIGITS_AFTER_DECIMAL_POINT = 1;
export const DECIMAL_PERCENTAGE_MAX_DIGITS_AFTER_DECIMAL_POINT = PERCENTAGE_MAX_DIGITS_AFTER_DECIMAL_POINT + 2;
export const HBO_MEMBER_ID = 'HBO';

export const redirectToForbidden = {
    redirect: {
        destination: '/403',
        permanent: false
    }
};

export function USD({ value, cents = true, options = {} }: { value: number; cents?: boolean; options?: object }) {
    const fractionDigits = value !== 0 ? 2 : 0;
    const opt = {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
        ...options
    };
    return Intl.NumberFormat('en-US', opt)
        .format(cents ? value / 100 : value)
        .replace('-', '–');
}

export function formatNumber({ value, cents = true, options = {} }: { value: number; options?: object; cents?: boolean }) {
    const fractionDigits = value !== 0 ? 2 : 0;
    const opt = {
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
        ...options
    };
    return Intl.NumberFormat('en-US', opt).format(cents ? value / 100 : value);
}

export function convertDecimalToPercentageText(decimal?: number | string): string {
    if (!decimal) return '';
    return convertDecimalToPercentage(decimal) + '%';
}

export function isValidDecimalPercentage(decimal?: number | string): boolean {
    if (!decimal) return false;
    const parsedDecimal = typeof decimal === 'string' ? parseFloat(decimal) : decimal;
    const roundedDecimal = roundUp(parsedDecimal, 3);
    return roundedDecimal >= 0 && roundedDecimal <= 1;
}
export function convertDecimalToPercentage(decimal?: number | string): string {
    if (!decimal) return '';
    const parsedDecimal = typeof decimal === 'string' ? parseFloat(decimal) : decimal;
    const roundedDecimal = roundUp(parsedDecimal, DECIMAL_PERCENTAGE_MAX_DIGITS_AFTER_DECIMAL_POINT);
    const numberAsPercentage = roundedDecimal * 100;
    return numberAsPercentage.toFixed(PERCENTAGE_MAX_DIGITS_AFTER_DECIMAL_POINT).replace('.0', '');
}

export function convertPercentageToDecimal(percentage?: number | string): string {
    if (!percentage) return '';
    const parsedPercentage = typeof percentage === 'string' ? parseFloat(percentage) : percentage;
    const roundedPercentage = roundUp(parsedPercentage, PERCENTAGE_MAX_DIGITS_AFTER_DECIMAL_POINT);

    return (roundedPercentage / 100).toFixed(DECIMAL_PERCENTAGE_MAX_DIGITS_AFTER_DECIMAL_POINT);
}

export function digitsOnly(value: string) {
    return value ? value.replace(/\D/g, '') : '';
}

export function removeSpecialCharacters(string: string) {
    return string.replace(/\W/g, '');
}

export function mask(value: string, start: number, length: number, mask = '*'): string {
    const startValue = value.substring(0, start);
    const maskedValue = mask.repeat(length);
    const endValue = value.substring(start + length, value.length);

    return `${startValue}${maskedValue}${endValue}`;
}

export function isMasked(value: string, mask = '*'): boolean {
    return value.includes(mask);
}

export function padStartWithMaskedChar({ value, totalLength, mask = '*' }: { value: string; totalLength: number; mask?: string }): string {
    return String(value).padStart(totalLength, mask);
}

export function formatDate(date: Date) {
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
    });
}

export function getCustomerTokenKey(customerId: string, fingerprint: string, purpose: CustomerTokenPurposeEnum) {
    return `${customerId}-${fingerprint}-${purpose}`;
}

export function roundUp(num: number, precision: number) {
    const precisionPow = Math.pow(10, precision);
    return Math.ceil(num * precisionPow) / precisionPow;
}

export function roundDown(num: number, precision: number) {
    const precisionPow = Math.pow(10, precision);
    return Math.floor(num * precisionPow) / precisionPow;
}

export function toCents(amount: number) {
    return Math.round(amount * 100);
}

export function toDollars(amount: number) {
    return amount / 100;
}

// checks if a string is null, undefined, or empty
export function isBlank(string: string | null | undefined) {
    return !string || string.trim().length === 0;
}

export function getAdditionalIncomeForeignIdByYear(year: string) {
    return `additional-income-${year}`;
}

export function getAdditionalExpenseForeignIdByYear(year: string) {
    return `additional-expense-${year}`;
}

export function camelCaseToTitleCase(str: string): string {
    if (!str) return str;
    return str
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, substring => substring.toUpperCase())
        .replace(/ +/g, ' ');
}

export function timeframeUnit(timeframe: Timeframe): TimeframeUnit {
    switch (timeframe) {
        case Timeframe.MonthToDate:
        case Timeframe.NextMonth:
        case Timeframe.ThisMonth:
            return TimeframeUnit.Day;
        case Timeframe.QuarterToDate:
        case Timeframe.YearToDate:
        case Timeframe.NextThreeMonths:
        case Timeframe.ThisYear:
            return TimeframeUnit.Month;
    }
}

export function timeframeToDates(timeRange: Timeframe, options?: { currentDate?: Date }) {
    const { currentDate } = options ?? {};
    const now = currentDate ?? new Date();
    const since = new Date(now);
    const until = new Date(now);

    const toStartOfDay = (date: Date) => date.setHours(0, 0, 0, 0);
    const toEndOfDay = (date: Date) => date.setHours(23, 59, 59, 999);
    const getQuarter = (date: Date) => Math.floor(date.getMonth() / 3);

    since.setDate(1);
    switch (timeRange) {
        case Timeframe.MonthToDate:
            break;
        case Timeframe.QuarterToDate:
            const quarter = getQuarter(since);
            since.setMonth(quarter * 3);
            break;
        case Timeframe.YearToDate:
            since.setMonth(0);
            break;
        case Timeframe.ThisMonth:
            until.setMonth(since.getMonth() + 1, 0);
            break;
        case Timeframe.NextMonth:
            since.setMonth(since.getMonth() + 1);
            until.setMonth(until.getMonth() + 2, 0);
            break;
        case Timeframe.NextThreeMonths:
            since.setMonth(since.getMonth() + 1);
            until.setMonth(until.getMonth() + 4, 0);
            break;
        case Timeframe.ThisYear:
            return { since: new Date(now.getFullYear(), 0, 1), until: new Date(now.getFullYear(), 11, 31) };
    }

    toStartOfDay(since);
    toEndOfDay(until);

    return { since, until };
}

export const timeframeToDisplayName = (timeframe: Timeframe): string => {
    const { since, until } = timeframeToDates(timeframe, { currentDate: new Date() });
    const monthOptions: Intl.DateTimeFormatOptions = { month: 'short' };
    const dayOptions: Intl.DateTimeFormatOptions = { day: 'numeric' };
    const yearOptions: Intl.DateTimeFormatOptions = { year: 'numeric' };

    const sinceMonth = new Intl.DateTimeFormat('en-US', monthOptions).format(since);
    const sinceDay = new Intl.DateTimeFormat('en-US', dayOptions).format(since);
    const untilMonth = new Intl.DateTimeFormat('en-US', monthOptions).format(until);
    const untilDay = new Intl.DateTimeFormat('en-US', dayOptions).format(until);
    const untilYear = new Intl.DateTimeFormat('en-US', yearOptions).format(until);

    return `${sinceMonth} ${sinceDay}–${untilMonth} ${untilDay}, ${untilYear}`;
};

export function hexToRgba(hex: string, alpha = 1) {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');

    // Parse the hex color
    let r, g, b;
    if (hex.length === 3) {
        r = parseInt(hex.charAt(0) + hex.charAt(0), 16);
        g = parseInt(hex.charAt(1) + hex.charAt(1), 16);
        b = parseInt(hex.charAt(2) + hex.charAt(2), 16);
    } else if (hex.length === 6) {
        r = parseInt(hex.substring(0, 2), 16);
        g = parseInt(hex.substring(2, 4), 16);
        b = parseInt(hex.substring(4, 6), 16);
    } else {
        throw new Error('Invalid HEX color.');
    }

    // Return the RGBA color
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

function mockDataPoint(): DataPoint {
    return {
        label: faker.date.recent(30).toISOString(),
        value: parseFloat(faker.finance.amount(0, 100000, 2)),
        count: 1
    };
}

function mockDataPoints(): DataPoint[] {
    const numberOfDataPoints = faker.datatype.number({ min: 1, max: 30 });
    return Array.from({ length: numberOfDataPoints }, () => mockDataPoint());
}

function mockDataset(): Dataset {
    return {
        label: faker.finance.accountName(),
        data: mockDataPoints()
    };
}

function mockDatasets(): Dataset[] {
    const numberOfDatasets = faker.datatype.number({ min: 1, max: 10 });
    return Array.from({ length: numberOfDatasets }, () => mockDataset());
}

export function mockChart(): Chart {
    return {
        datasets: mockDatasets()
    };
}
export const capitalizeString = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
