/* eslint-disable */
/* tslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
    Date: any;
    /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
    DateTime: any;
    /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSONObject: any;
};

export type Account = {
    __typename?: 'Account';
    /** The account limits */
    accountLimits?: Maybe<AccountLimits>;
    /** JSON object representing the account data. */
    attributes: AccountAttributes;
    /** Identifier of the account resource. */
    id: Scalars['ID'];
    /** Type of the account resource. */
    type: Scalars['String'];
};

export type AccountAttributes = {
    __typename?: 'AccountAttributes';
    /** The number of the account. */
    accountNumber: Scalars['String'];
    /** The available balance for spending (in cents). Equals the balance minus the hold amount. */
    available: Scalars['Float'];
    /** The balance amount (in cents). The balance represents the funds that are currently in the account (not taking into account future commitments). The balance equals the sum of 'available' and 'hold'. */
    balance: Scalars['Float'];
    status: AccountStatusEnum;
    tags: AccountTags;
};

export type AccountLimits = {
    __typename?: 'AccountLimits';
    /** JSON object representing the account limits data. */
    attributes: AccountLimitsAttributes;
};

export type AccountLimitsAttributes = {
    __typename?: 'AccountLimitsAttributes';
    /** Ach limits. */
    ach: AchLimits;
};

/** The Purpose of the account.  (tax/primary etc) */
export enum AccountPurposeEnum {
    LegacyPrimary = 'LegacyPrimary',
    LegacySavings = 'LegacySavings',
    LegacyTax = 'LegacyTax',
    Primary = 'Primary',
    Savings = 'Savings',
    Tax = 'Tax'
}

/** The status of an account.  (Open | Frozen | Closed) */
export enum AccountStatusEnum {
    Closed = 'Closed',
    Frozen = 'Frozen',
    Open = 'Open'
}

export type AccountTags = {
    __typename?: 'AccountTags';
    purpose: AccountPurposeEnum;
    /** undefined, smart, or custom number in range [0,1] for tax withholding. */
    taxWithholdingPercentage?: Maybe<Scalars['String']>;
};

/** Either Checking or Savings. */
export enum AccountType {
    Checking = 'Checking',
    Savings = 'Savings'
}

export type AchCounterPartyAttributesInput = {
    /** Bank account number. */
    accountNumber: Scalars['String'];
    /** Either Checking or Savings. */
    accountType?: InputMaybe<AccountType>;
    /** The account holder's name (whether an individual or a business). */
    name: Scalars['String'];
    /** Valid 9-digit ABA routing transit number. */
    routingNumber: Scalars['String'];
    /** Counterparty tags. */
    tags?: InputMaybe<CounterpartyTagsInput>;
    /** Either Business, Person or Unknown. */
    type: CounterpartyType;
};

export type AchCounterPartyInput = {
    /** The attributes of the counterparty. */
    attributes: AchCounterPartyAttributesInput;
};

export type AchCounterparty = {
    __typename?: 'AchCounterparty';
    /** JSON object representing the payment resource. */
    attributes: AchCounterpartyAttributes;
    /** Identifier of the ACH counterparty resource. */
    id: Scalars['ID'];
    /** Type of the ACH counterparty resource. */
    type: Scalars['String'];
};

export type AchCounterpartyAttributes = {
    __typename?: 'AchCounterpartyAttributes';
    /** Bank account number. */
    accountNumber: Scalars['String'];
    /** Either Checking or Savings. */
    accountType: AccountType;
    /** Name of the bank. */
    bank: Scalars['String'];
    /** The date the resource was created. */
    createdAt: Scalars['DateTime'];
    /** The account holder's name (whether an individual or a business). */
    name: Scalars['String'];
    /** Valid 9-digit ABA routing transit number. */
    routingNumber: Scalars['String'];
    /** Optional. Tags. */
    tags?: Maybe<CounterpartyTags>;
    /** Either Business, Person or Unknown. */
    type: CounterpartyType;
};

export type AchInlinePaymentAttributesInput = {
    /** Optional, additional payment description (maximum of 80 characters), not all institutions present that. */
    addenda?: InputMaybe<Scalars['String']>;
    /** The amount (cents) of the payment. */
    amount: Scalars['Int'];
    /** The party on the other side of the ACH payment. */
    counterparty: CounterpartyInput;
    /** Payment description (maximum of 10 characters), also known as Company Entry Description, this will show up on statement of the counterparty. */
    description: Scalars['String'];
    /** The direction in which the funds flow (either Debit or Credit). */
    direction?: InputMaybe<FundsFlowDirection>;
};

export type AchInlinePaymentInput = {
    /** The Deposit Account originating the payment. */
    accountId: Scalars['ID'];
    /** The attributes of the ACH payment. */
    attributes: AchInlinePaymentAttributesInput;
};

export type AchLimits = {
    __typename?: 'AchLimits';
    /** Ach limits attributes. */
    limits: AchLimitsAttributes;
    /** Total money transferred daily */
    totalsDaily: LimitTotalTransfer;
    /** Total money transferred monthly */
    totalsMonthly: LimitTotalTransfer;
};

export type AchLimitsAttributes = {
    __typename?: 'AchLimitsAttributes';
    /** Daily credit. */
    dailyCredit: Scalars['Float'];
    /** Monthly credit. */
    monthlyCredit: Scalars['Float'];
};

export type AchLinkedPaymentInput = {
    /** The Deposit Account originating the payment. */
    accountId: Scalars['ID'];
    /** The attributes of the ACH payment. */
    attributes: AchPaymentAttributesInput;
    /** The Counterparty the payment to be made to. */
    counterpartyId: Scalars['ID'];
};

export type AchPayment = {
    __typename?: 'AchPayment';
    /** JSON object representing the payment resource. */
    attributes: AchPaymentAttributes;
    /** Identifier of the ACH payment resource. */
    id: Scalars['ID'];
    /** Type of the payment resource. */
    type: Scalars['String'];
};

export type AchPaymentAttributes = {
    __typename?: 'AchPaymentAttributes';
    /** Optional, additional payment description (maximum of 50 characters), not all institutions present that. */
    addenda?: Maybe<Scalars['String']>;
    /** The amount (cents) of the payment. */
    amount?: Maybe<Scalars['Int']>;
    /** The party on the other side of the ACH payment. */
    counterparty: Counterparty;
    /** The date the resource was created. */
    createdAt: Scalars['DateTime'];
    /** Payment description (maximum of 10 characters), also known as Company Entry Description, this will show up on statement of the counterparty. */
    description: Scalars['String'];
    /** The direction in which the funds flow (either Debit or Credit). */
    direction: FundsFlowDirection;
    /** Optional. More information about the status. */
    reason?: Maybe<Scalars['String']>;
    /** One of Pending, PendingReview, Rejected, Clearing, Sent, Canceled, Returned. */
    status: PaymentStatus;
};

export type AchPaymentAttributesInput = {
    /** Optional, additional payment description (maximum of 80 characters), not all institutions present that. */
    addenda?: InputMaybe<Scalars['String']>;
    /** The amount (cents) of the payment. */
    amount: Scalars['Int'];
    /** Payment description (maximum of 10 characters), also known as Company Entry Description, this will show up on statement of the counterparty. */
    description: Scalars['String'];
    /** The direction in which the funds flow (either Debit or Credit). */
    direction?: InputMaybe<FundsFlowDirection>;
};

export type AchTransaction = Transaction & {
    __typename?: 'AchTransaction';
    /** The account ID connected to this transaction */
    accountId: Scalars['String'];
    attributes: AchTransactionAttributes;
    /** The expected completion date of the transaction */
    expectedCompletionDate?: Maybe<Scalars['DateTime']>;
    /** Identifier of the transaction resource. */
    id: Scalars['ID'];
    /** The project connected to this transaction */
    project?: Maybe<Project>;
    /** The recurring payment ID connected to this transaction */
    recurringPaymentId?: Maybe<Scalars['String']>;
    /** Type of the transaction resource. */
    type: Scalars['String'];
    /** A list of the withholdings made from this transaction */
    withholdings?: Maybe<Array<BucketWithhold>>;
};

export type AchTransactionAttributes = {
    __typename?: 'AchTransactionAttributes';
    /** Additional transaction description. */
    addenda?: Maybe<Scalars['String']>;
    /** The amount (cents) of the transaction. */
    amount: Scalars['Int'];
    /** The account balance (cents) after the transaction. */
    balance: Scalars['Int'];
    /** The category of the transaction */
    category?: Maybe<Scalars['String']>;
    /** The party on the other end of the transaction. */
    counterparty: AchTransactionCounterparty;
    /** The date the transaction was created */
    createdAt: Scalars['DateTime'];
    /** Transaction description. */
    description: Scalars['String'];
    /** The direction in which the funds flow - Debit or Credit. */
    direction: FundsFlowDirection;
    /** The 3-letter ACH Standard Entry Class (SEC) Code (e.g. WEB, CCD, PPD, etc.). */
    secCode: Scalars['String'];
    /** Human-friendly summary of the transaction. */
    summary: Scalars['String'];
    /** The tags of a transaction */
    tags?: Maybe<TransactionTags>;
    /** The title of the transaction */
    title: Scalars['String'];
    /** Optional. The 15-digit ACH Trace Number identifies the transaction within the ACH file after transmission. */
    traceNumber?: Maybe<Scalars['String']>;
};

export type AchTransactionCounterparty = {
    __typename?: 'AchTransactionCounterparty';
    /** Bank account number. */
    accountNumber: Scalars['String'];
    /** Either Checking or Savings. */
    accountType: Scalars['String'];
    /** Optional. See (this)[https://docs.unit.co/customer-api-tokens/#customers-create-customer-bearer-token-jwt] section for more information. */
    jwtSubject?: Maybe<Scalars['String']>;
    /** Name of the person or company that owns the bank account. */
    name: Scalars['String'];
    /** Valid 9-digit ABA routing transit number. */
    routingNumber: Scalars['String'];
};

export type AddCounterypartyRelationshipsArgs = {
    /** The counterparty relationships to be added. */
    counterpartyRelationships: Array<Scalars['String']>;
    customerId?: InputMaybe<Scalars['ID']>;
};

export type Address = {
    __typename?: 'Address';
    /** City. */
    city: Scalars['String'];
    /** Two letters representing the country. */
    country: Scalars['String'];
    /** Postal code. */
    postalCode: Scalars['String'];
    /** State. For US addresses, two letters representing the state. */
    state?: Maybe<Scalars['String']>;
    /** First line of an address. */
    street: Scalars['String'];
    /** Optional. Second line of an address. */
    street2?: Maybe<Scalars['String']>;
};

export type AddressInput = {
    /** City. */
    city: Scalars['String'];
    /** Two letters representing the country. */
    country: Scalars['String'];
    /** Postal code. */
    postalCode: Scalars['String'];
    /** State. For US addresses, two letters representing the state. */
    state?: InputMaybe<Scalars['String']>;
    /** First line of an address. */
    street: Scalars['String'];
    /** Optional. Second line of an address. */
    street2?: InputMaybe<Scalars['String']>;
};

export type Application = {
    /** The created Customer in case of approved application. */
    customer?: Maybe<Customer>;
    id: Scalars['ID'];
    type: ApplicationType;
};

export enum ApplicationStatus {
    Approved = 'Approved',
    AwaitingDocuments = 'AwaitingDocuments',
    Canceled = 'Canceled',
    Denied = 'Denied',
    Pending = 'Pending',
    PendingReview = 'PendingReview'
}

export type ApplicationTags = {
    __typename?: 'ApplicationTags';
    accountNumber?: Maybe<Scalars['String']>;
    fintechCustomerId?: Maybe<Scalars['String']>;
    hbAccountId: Scalars['String'];
    memberId: Scalars['String'];
    routingNumber?: Maybe<Scalars['String']>;
};

export enum ApplicationType {
    BusinessApplication = 'businessApplication',
    IndividualApplication = 'individualApplication'
}

export enum ApprovalStatus {
    Approved = 'Approved',
    Denied = 'Denied',
    PendingReview = 'PendingReview'
}

export type Authorization = {
    __typename?: 'Authorization';
    /** The account ID connected to this authorization. */
    accountId: Scalars['String'];
    attributes: AuthorizationAttributes;
    /** Identifier of the authorization resource. */
    id: Scalars['ID'];
    /** The project connected to this authorization. */
    project?: Maybe<Project>;
    /** Type of the authorization resource. */
    type: Scalars['String'];
};

export type AuthorizationAttributes = {
    __typename?: 'AuthorizationAttributes';
    /** The amount (cents) of the authorization. */
    amount: Scalars['Int'];
    /** The last 4 digits of the debit card involved in the authorization. */
    cardLast4Digits: Scalars['String'];
    /** The date the authorization was created */
    createdAt: Scalars['DateTime'];
    /** The type of digital wallet used, one of: Google, Apple, Other. */
    digitalWallet?: Maybe<DigitalWallet>;
    /** The merchant to which the authorization was made */
    merchant: Merchant;
    /** The payment method used, one of: Manual, Swipe, Contactless, ChipAndPin, Stored, Other. */
    paymentMethod?: Maybe<PaymentMethod>;
};

export type AuthorizedUser = {
    __typename?: 'AuthorizedUser';
    /** The authorized user's email address. */
    email: Scalars['String'];
    /** Full name of the authorized user. */
    fullName: FullName;
    /** Optional */
    jwtSubject?: Maybe<Scalars['String']>;
    /** The authorized user's phone number. This number will be used for One Time Password (OTP) authentication. */
    phone: Phone;
};

export type BaseTransaction = Transaction & {
    __typename?: 'BaseTransaction';
    /** The account ID connected to this transaction */
    accountId: Scalars['String'];
    attributes: TransactionAttributes;
    /** The expected completion date of the transaction */
    expectedCompletionDate?: Maybe<Scalars['DateTime']>;
    /** Identifier of the transaction resource. */
    id: Scalars['ID'];
    /** The project connected to this transaction */
    project?: Maybe<Project>;
    /** The recurring payment ID connected to this transaction */
    recurringPaymentId?: Maybe<Scalars['String']>;
    /** Type of the transaction resource. */
    type: Scalars['String'];
    /** A list of the withholdings made from this transaction */
    withholdings?: Maybe<Array<BucketWithhold>>;
};

export type BeneficialOwner = {
    __typename?: 'BeneficialOwner';
    /** The beneficial owner's address. */
    address: Address;
    /** Date only */
    dateOfBirth: Scalars['Date'];
    /** The beneficial owner's email address. */
    email: Scalars['String'];
    /** Full name of the beneficial owner. */
    fullName: FullName;
    /** Only when Passport is populated. Two letters representing the beneficial owner's nationality" */
    nationality?: Maybe<Scalars['String']>;
    /** Passport of the beneficial owner. One of ssn or passport is required. */
    passport?: Maybe<Scalars['String']>;
    /** The beneficial owner percentage of ownership at the business (between 0 and 100). */
    percentage: Scalars['Int'];
    /** The beneficial owner's phone number. */
    phone: Phone;
    /** SSN of the beneficial owner (numbers only). One of ssn or passport is required. */
    ssn?: Maybe<Scalars['String']>;
    /** One of Approved, Denied or PendingReview. */
    status?: Maybe<ApprovalStatus>;
};

export type BeneficialOwnerInput = {
    /** The beneficial owner's address. */
    address: AddressInput;
    /** Date only */
    dateOfBirth: Scalars['String'];
    /** The beneficial owner's email address. */
    email: Scalars['String'];
    /** Full name of the beneficial owner. */
    fullName: FullNameInput;
    /** Only when Passport is populated. Two letters representing the beneficial owner's nationality" */
    nationality?: InputMaybe<Scalars['String']>;
    /** Passport of the beneficial owner. One of ssn or passport is required. */
    passport?: InputMaybe<Scalars['String']>;
    /** The beneficial owner percentage of ownership at the business (between 0 and 100). */
    percentage: Scalars['Int'];
    /** The beneficial owner's phone number. */
    phone: PhoneInput;
    /** SSN of the beneficial owner (numbers only). One of ssn or passport is required. */
    ssn?: InputMaybe<Scalars['String']>;
    /** One of Approved, Denied or PendingReview. */
    status?: InputMaybe<ApprovalStatus>;
};

export type BookPaymentAttributesInput = {
    /** The direction in which the funds flow (either Debit or Credit). */
    direction?: InputMaybe<FundsFlowDirection>;
    /** Tags to set to payment. These tags will be copied to any transaction created by this payment. */
    tags?: InputMaybe<PaymentTagsInput>;
};

export type Bucket = {
    __typename?: 'Bucket';
    /** The icon of the bucket */
    icon: Scalars['String'];
    /** The ID of the bucket account */
    id: Scalars['ID'];
    /** Is this the tax bucket */
    isTaxBucket: Scalars['Boolean'];
    /** Legacy Smart Tax Rate */
    legacySmartTaxRate?: Maybe<Scalars['String']>;
    /** The name of the bucket */
    name: Scalars['String'];
    /** The balance of the bucket account */
    savings: Scalars['Float'];
    /** The percentage we want to move from each transaction to this bucket, for tax bucket using smart tax it will be 'smart' */
    savingsPercentage: Scalars['String'];
};

/** The status of the bucket, one of 'active'/'all'/'closed-with-balance' */
export enum BucketStatus {
    Active = 'Active',
    All = 'All',
    ClosedWithBalance = 'ClosedWithBalance'
}

export type BucketWithhold = {
    __typename?: 'BucketWithhold';
    /** The icon representing the bucket we withheld to at the time */
    bucketIcon?: Maybe<Scalars['String']>;
    /** The name of the bucket we withheld to at the time. */
    bucketName?: Maybe<Scalars['String']>;
    /** The amount withheld to the bucket */
    withholdAmount?: Maybe<Scalars['Float']>;
    /** The withhold percentage of the bucket we withheld to at the time */
    withholdPercentage?: Maybe<Scalars['String']>;
};

export type BusinessApplication = Application & {
    __typename?: 'BusinessApplication';
    attributes: BusinessApplicationAttributes;
    /** The created Customer in case of approved application. */
    customer?: Maybe<Customer>;
    id: Scalars['ID'];
    type: ApplicationType;
};

export type BusinessApplicationAttributes = {
    __typename?: 'BusinessApplicationAttributes';
    /** address of the business. */
    address: Address;
    /** Indicates whether the application has been archived. Archived applications are read-only and no changes can be made to them. An application becomes archived once the corresponding customer is archived. */
    archived: Scalars['Boolean'];
    /** Array of beneficial owners of the business. Beneficial Owner is anyone with more than 25% ownership. Beneficial Owners would need to go over KYC process and provide documents. */
    beneficialOwners: Array<BeneficialOwner>;
    /** Primary contact of the business. */
    contact: BusinessContact;
    /** The date the resource was created. */
    createdAt: Scalars['DateTime'];
    /** Optional. 'Doing business as'. */
    dba?: Maybe<Scalars['String']>;
    /** Business EIN (numbers only). */
    ein: Scalars['String'];
    /** One of 'Corporation', 'LLC' or 'Partnership'. */
    entityType: EntityType;
    /** A message describing the Application status. */
    message: Scalars['String'];
    /** Name of the business. */
    name: Scalars['String'];
    /** Officer representing the business, must be the CEO, COO, CFO, President, BenefitsAdministrationOfficer, CIO, VP, AVP, Treasurer, Secretary, Controller, Manager, Partner or Member. The officer would need to go over KYC process and provide documents. */
    officer: Officer;
    /** Phone of the business. */
    phone: Phone;
    /** Two letters representing a US state. */
    stateOfIncorporation: Scalars['String'];
    /** One of AwaitingDocuments, PendingReview, Approved, Denied or Pending */
    status: ApplicationStatus;
    tags: ApplicationTags;
};

export type BusinessApplicationAttributesInput = {
    /** address of the business. */
    address: AddressInput;
    /** Optional. Array of beneficial owners in the business. Beneficial owners are all people that, directly or indirectly, own 25% or more of the business. To onboard a business successfully, you must provide each beneficial owner's personal details. */
    beneficialOwners?: InputMaybe<Array<BeneficialOwnerInput>>;
    /** Optional. The business vertical of the business. */
    businessVertical: Scalars['String'];
    /** Primary contact of the business. */
    contact: BusinessContactInput;
    /** Optional. 'Doing business as'. */
    dba?: InputMaybe<Scalars['String']>;
    /** Array of Device Fingerprint */
    deviceFingerprints?: InputMaybe<Array<DeviceFingerprintInput>>;
    /** Business EIN (numbers only). */
    ein: Scalars['String'];
    /** One of 'Corporation', 'LLC' or 'Partnership'. */
    entityType: EntityType;
    /** Optional */
    idempotencyKey?: InputMaybe<Scalars['String']>;
    /** Optional. IP address of the end-customer creating the application. Both IPv4 and IPv6 formats are supported. Highly recommended as a fraud prevention measure, if the information is available when submitting the application. */
    ip?: InputMaybe<Scalars['String']>;
    /** Name of the business. */
    name: Scalars['String'];
    /** Officer representing the business. */
    officer: OfficerInput;
    /** Phone of the business. */
    phone: PhoneInput;
    /** Two letters representing a US state. */
    stateOfIncorporation: Scalars['String'];
    /** Optional. Business's website. */
    website: Scalars['String'];
    /** Optional. Year of incorporation of the business. */
    yearOfIncorporation: Scalars['String'];
};

export type BusinessApplicationInput = {
    /** Valid 9-digit ABA routing transit number. Used for creating ACH counterparty */
    accountNumber?: InputMaybe<Scalars['String']>;
    /** The attributes of the application. */
    attributes: BusinessApplicationAttributesInput;
    /** Bank account number. Used for creating ACH counterparty */
    routingNumber?: InputMaybe<Scalars['String']>;
};

export type BusinessContact = {
    __typename?: 'BusinessContact';
    /** The contact's email address. */
    email: Scalars['String'];
    /** Full name of the contact. */
    fullName: FullName;
    /** Optional */
    jwtSubject?: Maybe<Scalars['String']>;
    /** The contact's phone number. */
    phone: Phone;
};

export type BusinessContactInput = {
    /** The contact's email address. */
    email: Scalars['String'];
    /** Full name of the contact. */
    fullName: FullNameInput;
    /** Optional */
    jwtSubject?: InputMaybe<Scalars['String']>;
    /** The contact's phone number. */
    phone: PhoneInput;
};

export type BusinessCustomer = Customer & {
    __typename?: 'BusinessCustomer';
    attributes: BusinessCustomerAttributes;
    id: Scalars['ID'];
    /** The primary account for this customer */
    primaryAccount: Account;
    type: CustomerTypeEnum;
};

export type BusinessCustomerAttributes = {
    __typename?: 'BusinessCustomerAttributes';
    /** address of the business. */
    address: Address;
    /** Array of authorized users. An authorized user is someone who can participate in the One Time Password (OTP) authentication process. */
    authorizedUsers?: Maybe<Array<AuthorizedUser>>;
    /** Primary contact of the business. */
    contact: BusinessContact;
    /** The date the resource was created. */
    createdAt: Scalars['DateTime'];
    /** Optional. 'Doing business as' */
    dba?: Maybe<Scalars['String']>;
    /** Business EIN (numbers only). */
    ein: Scalars['String'];
    /** One of 'Corporation', 'LLC' or 'Partnership'. */
    entityType: EntityType;
    /** Name of the business. */
    name: Scalars['String'];
    /** Phone of the business. */
    phone: Phone;
    /** Two letters representing a US state. */
    stateOfIncorporation: Scalars['String'];
    tags: CustomerTags;
};

export type BusinessDebitCard = Card & {
    __typename?: 'BusinessDebitCard';
    /** JSON object representing the card data. */
    attributes: CardAttributes;
    /** Identifier of the card resource. */
    id: Scalars['ID'];
    /** Type of the card resource. */
    type: Scalars['String'];
};

export type BusinessVirtualDebitCard = Card & {
    __typename?: 'BusinessVirtualDebitCard';
    /** JSON object representing the card data. */
    attributes: BusinessVirtualDebitCardAttributes;
    /** Identifier of the card resource. */
    id: Scalars['ID'];
    /** Type of the card resource. */
    type: Scalars['String'];
};

export type BusinessVirtualDebitCardAttributes = {
    __typename?: 'BusinessVirtualDebitCardAttributes';
    /** The date the resource was created. */
    createdAt: Scalars['DateTime'];
    /** Card expiration date, formatted YYYY-MM, e.g "2020-05". */
    expirationDate: Scalars['String'];
    /** Full name of the card holder. */
    fullName: Scalars['String'];
    /** Last 4 digits of the debit card. */
    last4Digits: Scalars['String'];
    /** Status of the card, one of: Active, Inactive, Stolen, Lost, Frozen, ClosedByCustomer, SuspectedFraud. */
    status: CardStatus;
};

export type Card = {
    /** Identifier of the card resource. */
    id: Scalars['ID'];
    /** Type of the card resource. */
    type: Scalars['String'];
};

export type CardActionInput = {
    /** The card id to perform action on. */
    cardId: Scalars['ID'];
};

export type CardAttributes = {
    __typename?: 'CardAttributes';
    /** The date the resource was created. */
    createdAt: Scalars['DateTime'];
    /** Card expiration date, formatted YYYY-MM, e.g "2020-05". */
    expirationDate: Scalars['String'];
    /** Last 4 digits of the debit card. */
    last4Digits: Scalars['String'];
    /** Status of the card, one of: Active, Inactive, Stolen, Lost, Frozen, ClosedByCustomer, SuspectedFraud. */
    status: CardStatus;
};

/** Status of the card, one of: Active, Inactive, Stolen, Lost, Frozen, ClosedByCustomer, SuspectedFraud. */
export enum CardStatus {
    Active = 'Active',
    ClosedByCustomer = 'ClosedByCustomer',
    Frozen = 'Frozen',
    Inactive = 'Inactive',
    Lost = 'Lost',
    Stolen = 'Stolen',
    SuspectedFraud = 'SuspectedFraud'
}

export type Cashflow = {
    __typename?: 'Cashflow';
    /** The total balance amount for the selected time period. */
    balance: Scalars['Float'];
    /** The expense details for the selected time period. */
    expenseDetails: ExpenseDetails;
    /** The total expenses amount for the selected time period. */
    expenses: Scalars['Float'];
    /** The total income amount for the selected time period. */
    income: Scalars['Float'];
    /** The income details for the selected time period. */
    incomeDetails: IncomeDetails;
    /** The total savings amount for the selected time period. */
    savings: Scalars['Float'];
    /** The savings details for the selected time period. */
    savingsDetails: SavingsDetails;
};

/** Represents a chart with its datasets */
export type Chart = {
    __typename?: 'Chart';
    /** The collection of datasets that the chart contains. */
    datasets: Array<Dataset>;
};

export type Coordinates = {
    __typename?: 'Coordinates';
    /** The latitude value. */
    latitude: Scalars['Float'];
    /** The longitude value. */
    longitude: Scalars['Float'];
};

export type Counterparty = {
    __typename?: 'Counterparty';
    /** Bank account number. */
    accountNumber: Scalars['String'];
    /** Either Checking or Savings. */
    accountType: AccountType;
    /** Approval status of counterparty. */
    approvalStatus: Scalars['String'];
    /** Name of the person or company that owns the bank account. */
    name: Scalars['String'];
    /** Valid 9-digit ABA routing transit number. */
    routingNumber: Scalars['String'];
};

/** Approval status of counterparty. */
export enum CounterpartyApprovalStatus {
    Approved = 'Approved',
    Denied = 'Denied',
    Pending = 'Pending'
}

export type CounterpartyInput = {
    /** Bank account number. */
    accountNumber: Scalars['String'];
    /** Either Checking or Savings. */
    accountType: AccountType;
    /** Name of the person or company that owns the bank account. */
    name: Scalars['String'];
    /** Valid 9-digit ABA routing transit number. */
    routingNumber: Scalars['String'];
};

export type CounterpartyTags = {
    __typename?: 'CounterpartyTags';
    /** The approve status tag of a counterparty */
    approvalStatus?: Maybe<CounterpartyApprovalStatus>;
    /** The email tag of a counterparty */
    email?: Maybe<Scalars['String']>;
    /** The relationship tag of a counterparty */
    relationship?: Maybe<Scalars['String']>;
};

export type CounterpartyTagsInput = {
    /** The email tag of a counterparty */
    email?: InputMaybe<Scalars['String']>;
    /** The relationship tag of a counterparty */
    relationship?: InputMaybe<Scalars['String']>;
};

/** Either Business, Person or Unknown. */
export enum CounterpartyType {
    Business = 'Business',
    Person = 'Person',
    Unknown = 'Unknown'
}

export type CreateBookPaymentInput = {
    /** The amount (cents) of the payment. */
    amount: Scalars['Int'];
    /** Attributes of a book payment: direction and tags. */
    attributes?: InputMaybe<BookPaymentAttributesInput>;
    /** Payment description (maximum of 50 characters), also known as Company Entry Description, this will show up on statement of the counterparty. */
    description: Scalars['String'];
    /** The counterparty the payment will be made to. */
    destinationAccountId: Scalars['ID'];
    /** The account the payment will be made from. */
    sourceAccountId: Scalars['ID'];
};

export type Customer = {
    id: Scalars['ID'];
    /** The primary account for this customer */
    primaryAccount: Account;
    type: CustomerTypeEnum;
};

export type CustomerTags = {
    __typename?: 'CustomerTags';
    hbAccountId: Scalars['String'];
    memberId: Scalars['String'];
};

export type CustomerToken = {
    __typename?: 'CustomerToken';
    expiresIn?: Maybe<Scalars['Float']>;
    isMasked: Scalars['Boolean'];
    token?: Maybe<Scalars['String']>;
};

export enum CustomerTokenPurpose {
    FullPrivileges = 'FullPrivileges',
    ReadCardDetails = 'ReadCardDetails',
    TransferMoney = 'TransferMoney'
}

export enum CustomerTypeEnum {
    Business = 'Business',
    Individual = 'Individual'
}

/** Represents an individual data point within a dataset. */
export type DataPoint = {
    __typename?: 'DataPoint';
    /** The amount of objects in the data point. */
    count: Scalars['Float'];
    /** The label for the data point, such as a category name or timestamp. */
    label: Scalars['String'];
    /** The numerical value of the data point. */
    value: Scalars['Float'];
};

/** Represents a dataset within a chart, including its data points and styling. */
export type Dataset = {
    __typename?: 'Dataset';
    /** The data points that make up the dataset. */
    data: Array<DataPoint>;
    /** The label for the dataset, used in legends and tooltips. */
    label: Scalars['String'];
};

export enum DayOfWeek {
    Friday = 'Friday',
    Monday = 'Monday',
    Saturday = 'Saturday',
    Sunday = 'Sunday',
    Thursday = 'Thursday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday'
}

export type DebitCardInput = {
    /** The account the card belongs to. */
    accountId: Scalars['ID'];
    customerId?: InputMaybe<Scalars['ID']>;
};

export type DeleteAchCounterypartyArgs = {
    /** Identifier of the ACH counterparty resource. */
    counterpartyId: Scalars['ID'];
};

export type DeviceFingerprintInput = {
    /** Provider of the device fingerprint fraud and risk prevention. The value is always iovation */
    provider: Scalars['String'];
    /** The device fingerprint blackbox value. */
    value: Scalars['String'];
};

export enum DigitalWallet {
    Apple = 'APPLE',
    Google = 'GOOGLE',
    Other = 'OTHER'
}

export enum EntityType {
    Corporation = 'Corporation',
    Llc = 'LLC',
    Partnership = 'Partnership'
}

export type ExpenseDetails = {
    __typename?: 'ExpenseDetails';
    /** Total sum of expenses from ACH payments */
    achPayment: Scalars['Float'];
    /** Total sum of expenses from ACH transactions */
    achTransaction: Scalars['Float'];
    /** Total sum of money withdrawn from an ATM */
    atmTransaction: Scalars['Float'];
    /** Total sum of money spent on card payments */
    cardTransaction: Scalars['Float'];
    /** Total sum of expenses from other sources */
    miscellaneous: Scalars['Float'];
};

export type ExternalAccount = {
    __typename?: 'ExternalAccount';
    /** Available balance */
    availableBalance: Scalars['Float'];
    /** Current balance */
    currentBalance: Scalars['Float'];
    /** Official account name */
    displayName: Scalars['String'];
    /** Institution ID */
    institutionId: Scalars['String'];
    /** Institution name */
    institutionName?: Maybe<Scalars['String']>;
    /** The date the account was last updated */
    lastUpdated?: Maybe<Scalars['DateTime']>;
    /** Logo of institution associated with the external account. */
    logo?: Maybe<Scalars['String']>;
    /** The link status of this account */
    status: PlaidInstitutionAuthStatus;
    /** Account subtype */
    subtype: Scalars['String'];
};

export type FullName = {
    __typename?: 'FullName';
    /** Individual first name. */
    first: Scalars['String'];
    /** Individual last name. */
    last: Scalars['String'];
};

export type FullNameInput = {
    /** Individual first name. */
    first: Scalars['String'];
    /** Individual last name. */
    last: Scalars['String'];
};

/** The direction in which the funds flow- Debit or Credit. */
export enum FundsFlowDirection {
    Credit = 'Credit',
    Debit = 'Debit'
}

/** Common structure for different types of goals */
export type Goal = {
    /** The date and time when the goal was created */
    createdAt: Scalars['String'];
    /** The end date of the goal period in ISO format (e.g., YYYY-MM-DD) */
    endDate: Scalars['Date'];
    /** Unique identifier for the goal */
    id: Scalars['ID'];
    /** The start date of the goal period in ISO format (e.g., YYYY-MM-DD) */
    startDate: Scalars['Date'];
    /** Current status of the goal */
    status: GoalStatus;
    /** The target amount set for the goal */
    target: Scalars['Float'];
};

/** Input structure for creating or updating a goal */
export type GoalInput = {
    /** The target amount for the goal */
    target: Scalars['Float'];
};

/** The status of the goal, indicating whether it is in progress, achieved, or not set. */
export enum GoalStatus {
    Achieved = 'ACHIEVED',
    InProgress = 'IN_PROGRESS',
    NotAchieved = 'NOT_ACHIEVED',
    NotSet = 'NOT_SET'
}

export type HbSubscription = {
    __typename?: 'HBSubscription';
    /** The payment method for the subscription plan. */
    paymentMethod?: Maybe<SubscriptionPaymentMethod>;
};

export type IncomeDetails = {
    __typename?: 'IncomeDetails';
    /** Total sum of income from client payments */
    clientPayments: Scalars['Float'];
    /** Total sum of income from other sources */
    miscellaneous: Scalars['Float'];
    /** Total sum of income from received ACH transfers */
    receivedAchTransaction: Scalars['Float'];
};

export type IndividualApplication = Application & {
    __typename?: 'IndividualApplication';
    attributes: IndividualApplicationAttributes;
    /** The created Customer in case of approved application. */
    customer?: Maybe<Customer>;
    id: Scalars['ID'];
    type: ApplicationType;
};

export type IndividualApplicationAttributes = {
    __typename?: 'IndividualApplicationAttributes';
    /** address of the individual. */
    address: Address;
    /** The date the resource was created. */
    createdAt: Scalars['DateTime'];
    /** Date only */
    dateOfBirth: Scalars['Date'];
    /** Optional. Indicates if the individual is a sole proprietor who is doing business under a different name, if specified. */
    dba?: Maybe<Scalars['String']>;
    /** Optional. Indicates if the individual is a sole proprietor who has an Employer Identification Number, if specified. */
    ein?: Maybe<Scalars['String']>;
    /** Email address of the individual. */
    email: Scalars['String'];
    /** Only when Passport is populated. Two letters representing the individual nationality. */
    fullName: FullName;
    /** IP address of the end-customer creating the application, if specified. */
    ip?: Maybe<Scalars['String']>;
    /** A message describing the Application status. */
    message: Scalars['String'];
    /** Only when Passport is populated. Two letters representing the individual nationality. */
    nationality?: Maybe<Scalars['String']>;
    /** Individual passport number. Either ssn or passport will be populated. */
    passport?: Maybe<Scalars['String']>;
    /** Phone of the individual. */
    phone: Phone;
    /** Optional. Indicates whether the individual is a sole proprietor, if specified. */
    soleProprietorship?: Maybe<Scalars['Boolean']>;
    /** SSN of the individual (numbers only). Either ssn or passport will be populated. */
    ssn?: Maybe<Scalars['String']>;
    /** One of AwaitingDocuments, PendingReview, Approved, Denied or Pending */
    status: ApplicationStatus;
    tags: ApplicationTags;
};

export type IndividualApplicationAttributesInput = {
    /** address of the individual. */
    address: AddressInput;
    /** Optional. The business vertical of the business. */
    businessVertical: Scalars['String'];
    /** Date only */
    dateOfBirth: Scalars['String'];
    /** Optional. Indicates if the individual is a sole proprietor who is doing business under a different name, if specified. */
    dba?: InputMaybe<Scalars['String']>;
    /** Array of Device Fingerprint */
    deviceFingerprints?: InputMaybe<Array<DeviceFingerprintInput>>;
    /** Optional. Indicates if the individual is a sole proprietor who has an Employer Identification Number, if specified. */
    ein?: InputMaybe<Scalars['String']>;
    /** Email address of the individual. */
    email: Scalars['String'];
    /** Only when Passport is populated. Two letters representing the individual nationality. */
    fullName: FullNameInput;
    /** Optional */
    idempotencyKey?: InputMaybe<Scalars['String']>;
    /** Optional. IP address of the end-customer creating the application. Both IPv4 and IPv6 formats are supported. Highly recommended as a fraud prevention measure, if the information is available when submitting the application. */
    ip?: InputMaybe<Scalars['String']>;
    /** Only when Passport is populated. Two letters representing the individual nationality. */
    nationality?: InputMaybe<Scalars['String']>;
    /** Individual passport number. Either ssn or passport will be populated. */
    passport?: InputMaybe<Scalars['String']>;
    /** Phone of the individual. */
    phone: PhoneInput;
    /** Optional. Indicates whether the individual is a sole proprietor, if specified. */
    soleProprietorship?: InputMaybe<Scalars['Boolean']>;
    /** SSN of the individual (numbers only). Either ssn or passport will be populated. */
    ssn?: InputMaybe<Scalars['String']>;
    /** Optional. Business's website. */
    website: Scalars['String'];
};

export type IndividualApplicationInput = {
    /** Valid 9-digit ABA routing transit number. Used for creating ACH counterparty */
    accountNumber?: InputMaybe<Scalars['String']>;
    /** The attributes of the application. */
    attributes: IndividualApplicationAttributesInput;
    /** Bank account number. Used for creating ACH counterparty */
    routingNumber?: InputMaybe<Scalars['String']>;
};

export type IndividualCustomer = Customer & {
    __typename?: 'IndividualCustomer';
    attributes: IndividualCustomerAttributes;
    id: Scalars['ID'];
    /** The primary account for this customer */
    primaryAccount: Account;
    type: CustomerTypeEnum;
};

export type IndividualCustomerAttributes = {
    __typename?: 'IndividualCustomerAttributes';
    /** address of the individual. */
    address: Address;
    /** Array of authorized users. An authorized user is someone who can participate in the One Time Password (OTP) authentication process. */
    authorizedUsers?: Maybe<Array<AuthorizedUser>>;
    /** The date the resource was created. */
    createdAt: Scalars['DateTime'];
    dateOfBirth: Scalars['Date'];
    /** Email address of the individual. */
    email: Scalars['String'];
    /** Full name of the individual. */
    fullName: FullName;
    /** Only when Passport is populated. Two letters representing the individual nationality */
    nationality?: Maybe<Scalars['String']>;
    /** Individual passport number. Either ssn or passport will be populated. */
    passport?: Maybe<Scalars['String']>;
    /** Phone of the individual. */
    phone: Phone;
    /** Individual ssn number. Either ssn or passport will be populated. */
    ssn?: Maybe<Scalars['String']>;
    tags: CustomerTags;
};

export type IndividualDebitCard = Card & {
    __typename?: 'IndividualDebitCard';
    /** JSON object representing the card data. */
    attributes: CardAttributes;
    /** Identifier of the card resource. */
    id: Scalars['ID'];
    /** Type of the card resource. */
    type: Scalars['String'];
};

export type IndividualVirtualDebitCard = Card & {
    __typename?: 'IndividualVirtualDebitCard';
    /** JSON object representing the card data. */
    attributes: CardAttributes;
    /** Identifier of the card resource. */
    id: Scalars['ID'];
    /** Type of the card resource. */
    type: Scalars['String'];
};

export enum Interval {
    Monthly = 'Monthly',
    Weekly = 'Weekly'
}

export type LimitTotalTransfer = {
    __typename?: 'LimitTotalTransfer';
    /** Total money transferred via credit. */
    credits: Scalars['Float'];
};

export type Merchant = {
    __typename?: 'Merchant';
    /** The merchant category, described by the MCC code */
    category: Scalars['String'];
    /** Optional. The location (city, state, etc.) of the merchant */
    location?: Maybe<Scalars['String']>;
    /** The name of the merchant. */
    name: Scalars['String'];
    /** The 4-digit ISO 18245 merchant category code (MCC). */
    type: Scalars['Int'];
};

export type Mutation = {
    __typename?: 'Mutation';
    /** Add a counterparty relationship for a specific customer */
    addCounterpartyRelationships: Array<Scalars['String']>;
    /** Create an application for a business end-customer. */
    businessApplication: BusinessApplication;
    /** Close a bucket and transfer its balance to the customer's primary account */
    closeBucket: Account;
    /** Close a card. */
    closeCard: Card;
    /** Connect to project */
    connectProjectToAuthorization: Authorization;
    /** Originates a payment between two accounts. */
    createBookPayment: AchPayment;
    /** Creates a counterparty for a specific customer. */
    createCounterParty: AchCounterparty;
    /** creates customer token */
    createCustomerToken?: Maybe<CustomerToken>;
    /** Creates a debit card for a specific customer and account. */
    createDebitCard: Card;
    /** Originates an ACH payment to an inline counterparty. */
    createInlinePayment: AchPayment;
    /** Originates an ACH payment to a linked counterparty. */
    createLinkedPayment?: Maybe<AchPayment>;
    /** Create a recurring ACH credit payment. */
    createRecurringAchCreditPayment: RecurringAchCreditPayment;
    /** Opens a new bucket, in case of the tax bucket it will re-open the tax account / adjust it's withhold percentage */
    createSavingsBucket: Bucket;
    /** Create token verification before customer token. */
    createTokenVerification?: Maybe<Scalars['String']>;
    /** Creates a virtual debit card for a specific customer and account. */
    createVirtualDebitCard: Card;
    /** Creates a new yearly revenue goal for the user */
    createYearlyRevenueGoal: YearlyRevenueGoal;
    /** Delete a counterparty for a specific customer */
    deleteCounterparty: Scalars['ID'];
    /** Delete plaid institution auth */
    deletePlaidInstitutionAuth: Scalars['Boolean'];
    /** Exchange public token with link token */
    exchangePublicToken: Scalars['Boolean'];
    /** Freeze a card (temporarily block a card). */
    freezeCard: Card;
    /** Create an application for an individual end-customer. */
    individualApplication: IndividualApplication;
    /** Exchange public token with link token */
    markItemRepaired: Scalars['Boolean'];
    /** Remove a counterparty relationship for a specific customer */
    removeCounterpartyRelationships: Array<Scalars['String']>;
    /** Save a UI preference. */
    saveUIPreference: UiPreference;
    /** Creates the accounts for a new customer */
    setupAccounts: Array<Account>;
    /** Toggle activation status */
    toggleRecurringAchCreditPaymentActivationStatus: RecurringAchCreditPayment;
    /** Transfer a bucket's account to the customer's primary account */
    transferBucketBalance: Bucket;
    /** Transfer a bucket's account to the customer's primary account */
    transferFromBucketBalance: Bucket;
    /** Transfer a bucket's account to the customer's primary account */
    transferToBucketBalance: Bucket;
    /** Unfreeze a frozen card. */
    unfreezeCard: Card;
    /** Update account by customerId and purpose */
    updateAccount: Account;
    /** Update the savings percentage of the bucket */
    updateBucket: Bucket;
    /** Updates a counterparty for a specific customer. */
    updateCounterparty: AchCounterparty;
    /** Update a transaction resource. */
    updateTransaction: Transaction;
};

export type MutationAddCounterpartyRelationshipsArgs = {
    data: AddCounterypartyRelationshipsArgs;
};

export type MutationBusinessApplicationArgs = {
    data: BusinessApplicationInput;
};

export type MutationCloseBucketArgs = {
    bucketId: Scalars['String'];
    transferBalance?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCloseCardArgs = {
    data: CardActionInput;
};

export type MutationConnectProjectToAuthorizationArgs = {
    authorizationId: Scalars['ID'];
    customerId?: InputMaybe<Scalars['ID']>;
    projectId: Scalars['ID'];
};

export type MutationCreateBookPaymentArgs = {
    data: CreateBookPaymentInput;
};

export type MutationCreateCounterPartyArgs = {
    data: AchCounterPartyInput;
};

export type MutationCreateCustomerTokenArgs = {
    customerId?: InputMaybe<Scalars['ID']>;
    fingerprint?: InputMaybe<Scalars['ID']>;
    purpose: CustomerTokenPurpose;
    verificationCode: Scalars['ID'];
    verificationToken: Scalars['ID'];
};

export type MutationCreateDebitCardArgs = {
    data: DebitCardInput;
};

export type MutationCreateInlinePaymentArgs = {
    data: AchInlinePaymentInput;
};

export type MutationCreateLinkedPaymentArgs = {
    data: AchLinkedPaymentInput;
};

export type MutationCreateRecurringAchCreditPaymentArgs = {
    data: RecurringAchCreditPaymentInput;
};

export type MutationCreateSavingsBucketArgs = {
    bucketIcon: Scalars['String'];
    bucketName: Scalars['String'];
    bucketSavingsPercentage: Scalars['String'];
    customerId?: InputMaybe<Scalars['ID']>;
    isTaxBucket: Scalars['Boolean'];
};

export type MutationCreateTokenVerificationArgs = {
    channel: UnitVerificationTokenChannel;
    customerId?: InputMaybe<Scalars['ID']>;
};

export type MutationCreateVirtualDebitCardArgs = {
    data: DebitCardInput;
};

export type MutationCreateYearlyRevenueGoalArgs = {
    data: GoalInput;
};

export type MutationDeleteCounterpartyArgs = {
    data: DeleteAchCounterypartyArgs;
};

export type MutationDeletePlaidInstitutionAuthArgs = {
    institutionId: Scalars['String'];
};

export type MutationExchangePublicTokenArgs = {
    activeAccountIds: Array<Scalars['String']>;
    institutionId: Scalars['String'];
    institutionName: Scalars['String'];
    token: Scalars['String'];
};

export type MutationFreezeCardArgs = {
    data: CardActionInput;
};

export type MutationIndividualApplicationArgs = {
    data: IndividualApplicationInput;
};

export type MutationMarkItemRepairedArgs = {
    activeAccountIds: Array<Scalars['String']>;
    institutionId: Scalars['String'];
};

export type MutationRemoveCounterpartyRelationshipsArgs = {
    data: RemoveCounterypartyRelationshipsArgs;
};

export type MutationSaveUiPreferenceArgs = {
    input: SaveUiPreferenceInput;
};

export type MutationToggleRecurringAchCreditPaymentActivationStatusArgs = {
    data: ToggleRecurringPaymentStatusInput;
};

export type MutationTransferBucketBalanceArgs = {
    bucketId: Scalars['String'];
};

export type MutationTransferFromBucketBalanceArgs = {
    amount?: InputMaybe<Scalars['Float']>;
    bucketId: Scalars['String'];
};

export type MutationTransferToBucketBalanceArgs = {
    amount?: InputMaybe<Scalars['Float']>;
    bucketId: Scalars['String'];
};

export type MutationUnfreezeCardArgs = {
    data: CardActionInput;
};

export type MutationUpdateAccountArgs = {
    data: UpdateAccountInput;
};

export type MutationUpdateBucketArgs = {
    bucketIcon?: InputMaybe<Scalars['String']>;
    bucketId: Scalars['String'];
    bucketName?: InputMaybe<Scalars['String']>;
    savingsPercentage?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateCounterpartyArgs = {
    data: UpdateAchCounterypartyArgs;
};

export type MutationUpdateTransactionArgs = {
    data: UpdateTransactionInput;
};

export type Officer = {
    __typename?: 'Officer';
    /** The officer's address. */
    address: Address;
    /** Date only */
    dateOfBirth: Scalars['Date'];
    /** The officer's email address. */
    email: Scalars['String'];
    /** Full name of the officer. */
    fullName: FullName;
    /** Only when Passport is populated. Two letters representing the officer's nationality. */
    nationality?: Maybe<Scalars['String']>;
    /** Passport of the officer. One of ssn or passport is required. */
    passport?: Maybe<Scalars['String']>;
    /** The officer's phone number. */
    phone: Phone;
    /** SSN of the officer (numbers only). One of ssn or passport is required. */
    ssn?: Maybe<Scalars['String']>;
    /** One of Approved, Denied or PendingReview. */
    status?: Maybe<ApprovalStatus>;
    /** One of CEO, COO, CFO, President, BenefitsAdministrationOfficer, CIO, VP, AVP, Treasurer, Secretary, Controller, Manager, Partner or Member. */
    title: OfficerTitle;
};

export type OfficerInput = {
    /** The officer's address. */
    address: AddressInput;
    /** Date only */
    dateOfBirth: Scalars['String'];
    /** The officer's email address. */
    email: Scalars['String'];
    /** Full name of the officer. */
    fullName: FullNameInput;
    /** Only when Passport is populated. Two letters representing the officer's nationality. */
    nationality?: InputMaybe<Scalars['String']>;
    /** Passport of the officer. One of ssn or passport is required. */
    passport?: InputMaybe<Scalars['String']>;
    /** The officer's phone number. */
    phone: PhoneInput;
    /** SSN of the officer (numbers only). One of ssn or passport is required. */
    ssn?: InputMaybe<Scalars['String']>;
    /** One of Approved, Denied or PendingReview. */
    status?: InputMaybe<ApprovalStatus>;
    /** One of CEO, COO, CFO, President, BenefitsAdministrationOfficer, CIO, VP, AVP, Treasurer, Secretary, Controller, Manager, Partner or Member. */
    title: OfficerTitle;
};

export enum OfficerTitle {
    Avp = 'AVP',
    BenefitsAdministrationOfficer = 'BenefitsAdministrationOfficer',
    Ceo = 'CEO',
    Cfo = 'CFO',
    Cio = 'CIO',
    Coo = 'COO',
    Controller = 'Controller',
    Manager = 'Manager',
    Member = 'Member',
    Partner = 'Partner',
    President = 'President',
    Secretary = 'Secretary',
    Treasurer = 'Treasurer',
    Vp = 'VP'
}

export enum PaymentMethod {
    ChipAndPin = 'CHIP_AND_PIN',
    Contactless = 'CONTACTLESS',
    Manual = 'MANUAL',
    Other = 'OTHER',
    Stored = 'STORED',
    Swipe = 'SWIPE'
}

export enum PaymentStatus {
    Canceled = 'Canceled',
    Clearing = 'Clearing',
    Pending = 'Pending',
    PendingReview = 'PendingReview',
    Rejected = 'Rejected',
    Returned = 'Returned',
    Sent = 'Sent'
}

export type PaymentTagsInput = {
    purpose: Scalars['String'];
};

export type Phone = {
    __typename?: 'Phone';
    /** Country code of the phone number. */
    countryCode: Scalars['String'];
    /** The phone number without the country code. */
    number: Scalars['String'];
};

export type PhoneInput = {
    /** Country code of the phone number. */
    countryCode: Scalars['String'];
    /** The phone number without the country code. */
    number: Scalars['String'];
};

/** The status of the institution authentication */
export enum PlaidInstitutionAuthStatus {
    AboutToExpire = 'AboutToExpire',
    Deleted = 'Deleted',
    NeedRelogin = 'NeedRelogin',
    Ok = 'Ok',
    PendingData = 'PendingData'
}

export type Project = {
    __typename?: 'Project';
    /** Identifier of the HB project resource. */
    id: Scalars['ID'];
    /** Whether the HB project resource is deleted. */
    isDeleted?: Maybe<Scalars['Boolean']>;
    /** The name of the HB project resource. */
    name: Scalars['String'];
    /** The workspace id of the HB project resource. */
    workspaceId?: Maybe<Scalars['String']>;
};

export type PurchaseTransaction = Transaction & {
    __typename?: 'PurchaseTransaction';
    /** The account ID connected to this transaction */
    accountId: Scalars['String'];
    attributes: PurchaseTransactionAttributes;
    /** The expected completion date of the transaction */
    expectedCompletionDate?: Maybe<Scalars['DateTime']>;
    /** Identifier of the transaction resource. */
    id: Scalars['ID'];
    /** The project connected to this transaction */
    project?: Maybe<Project>;
    /** The recurring payment ID connected to this transaction */
    recurringPaymentId?: Maybe<Scalars['String']>;
    /** Type of the transaction resource. */
    type: Scalars['String'];
    /** A list of the withholdings made from this transaction */
    withholdings?: Maybe<Array<BucketWithhold>>;
};

export type PurchaseTransactionAttributes = {
    __typename?: 'PurchaseTransactionAttributes';
    /** The amount (cents) of the transaction. */
    amount: Scalars['Int'];
    /** The account balance (cents) after the transaction. */
    balance: Scalars['Int'];
    /** The last 4 digits of the debit card involved in the transaction. */
    cardLast4Digits: Scalars['String'];
    /** The category of the transaction */
    category?: Maybe<Scalars['String']>;
    /** Coordinates (latitude, longitude) of where the purchase took place. */
    coordinates?: Maybe<Coordinates>;
    /** The date the transaction was created */
    createdAt: Scalars['DateTime'];
    /** The type of digital wallet used, one of: Google, Apple, Other. */
    digitalWallet?: Maybe<DigitalWallet>;
    /** The direction in which the funds flow - Debit or Credit. */
    direction: FundsFlowDirection;
    /** The merchant to which the transaction was made */
    merchant: Merchant;
    /** The payment method used, one of: Manual, Swipe, Contactless, ChipAndPin, Stored, Other. */
    paymentMethod?: Maybe<PaymentMethod>;
    /** Human-friendly summary of the transaction. */
    summary: Scalars['String'];
    /** The tags of a transaction */
    tags?: Maybe<TransactionTags>;
    /** The title of the transaction */
    title: Scalars['String'];
};

export type Query = {
    __typename?: 'Query';
    /** account data. */
    accountById: Account;
    /** account data. */
    accountByPurpose: Account;
    /** List account authorizations. */
    authorizations: Array<Authorization>;
    /** Retrieve a chart for balance details */
    balanceDetails?: Maybe<Chart>;
    /** Fetch all active buckets for the specified customer */
    buckets: Array<Bucket>;
    /** Get all cards for a specific customer. */
    cards: Array<Card>;
    /** Fetch cashflow info for the given timeframe */
    cashflow: Cashflow;
    /** List counterparties. Filtering and paging can be applied. */
    counterparties: Array<AchCounterparty>;
    /** Get counterparty relationships for a specific customer */
    counterpartyRelationships: Array<Scalars['String']>;
    /** Create plaid link token */
    createLinkToken: Token;
    /** Create plaid link token for reconnect */
    createLinkTokenForReconnect: Token;
    /** Get a customer resource by id. */
    customer?: Maybe<Customer>;
    /** Get all external accounts session customer. */
    externalAccounts: Array<ExternalAccount>;
    /** Get authorization by id. */
    getAuthorization: Authorization;
    /** Gets if a customer token with a given scope already exists and the token itself if allowed */
    getExistingCustomerToken?: Maybe<CustomerToken>;
    /** Get all UI preferences. */
    getUIPreferences: Array<UiPreference>;
    /** Retrieve a chart for hb payments */
    hbPayments?: Maybe<Chart>;
    /** Get the HB subscription plan for the current user. (using the session) */
    hbSubscription?: Maybe<HbSubscription>;
    /** return the specific month that hed transactions in a given year */
    monthsWithTransactionsByYear: Array<Scalars['Float']>;
    /** List the account's pending payments */
    pendingPayments: Array<AchPayment>;
    /** List all recurring ACH credit payments */
    recurringAchCreditPayments: Array<RecurringAchCreditPayment>;
    /** get all saving accounts. */
    savingAccounts: Array<Account>;
    /** Search for projects */
    search: Array<Project>;
    /** statement document as html string. */
    statementHTMLById: Scalars['String'];
    /** statement document as pdf array buffer. */
    statementPDFById: Array<Scalars['Float']>;
    /** list of statement data. */
    statements: Array<Statement>;
    /** Retrieve a transaction resource. */
    transaction: Transaction;
    /** List transaction resources. */
    transactions: Array<Transaction>;
    /** transactions document as csv array buffer. */
    transactionsCSV: Scalars['String'];
    /** The response object of the get upload token request */
    uploadToken: Scalars['String'];
};

export type QueryAccountByIdArgs = {
    accountId: Scalars['ID'];
};

export type QueryAccountByPurposeArgs = {
    customerId?: InputMaybe<Scalars['ID']>;
    purpose: AccountPurposeEnum;
};

export type QueryAuthorizationsArgs = {
    accountId: Scalars['ID'];
};

export type QueryBalanceDetailsArgs = {
    since: Scalars['DateTime'];
    timeframeUnit: TimeframeUnit;
    timezone: Scalars['String'];
    type: Scalars['String'];
    until: Scalars['DateTime'];
};

export type QueryBucketsArgs = {
    bucketStatus?: InputMaybe<BucketStatus>;
    customerId?: InputMaybe<Scalars['ID']>;
};

export type QueryCashflowArgs = {
    since: Scalars['DateTime'];
    until: Scalars['DateTime'];
};

export type QueryCreateLinkTokenArgs = {
    plaidProducts: Array<Scalars['String']>;
};

export type QueryCreateLinkTokenForReconnectArgs = {
    institutionId: Scalars['String'];
};

export type QueryCustomerArgs = {
    hbId?: InputMaybe<Scalars['ID']>;
    id?: InputMaybe<Scalars['ID']>;
};

export type QueryGetAuthorizationArgs = {
    authorizationId: Scalars['ID'];
    customerId?: InputMaybe<Scalars['ID']>;
    includeNonAuthorized: Scalars['Boolean'];
};

export type QueryGetExistingCustomerTokenArgs = {
    purpose: CustomerTokenPurpose;
};

export type QueryHbPaymentsArgs = {
    since: Scalars['Date'];
    timeframeUnit: TimeframeUnit;
    timezone: Scalars['String'];
    until: Scalars['Date'];
};

export type QueryMonthsWithTransactionsByYearArgs = {
    accountId: Scalars['ID'];
    direction?: InputMaybe<Array<Scalars['String']>>;
    excludeFees?: InputMaybe<Scalars['Boolean']>;
    excludeTaxes?: InputMaybe<Scalars['Boolean']>;
    excludeWithhold?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    since?: InputMaybe<Scalars['String']>;
    sort?: InputMaybe<Scalars['String']>;
    tags?: InputMaybe<TransactionTagsInput>;
    type?: InputMaybe<Array<Scalars['String']>>;
    until?: InputMaybe<Scalars['String']>;
    year: Scalars['Float'];
};

export type QueryPendingPaymentsArgs = {
    accountId: Scalars['ID'];
};

export type QuerySearchArgs = {
    limit?: InputMaybe<Scalars['Float']>;
    query: Scalars['String'];
};

export type QueryStatementHtmlByIdArgs = {
    customerId?: InputMaybe<Scalars['ID']>;
    statementId: Scalars['ID'];
};

export type QueryStatementPdfByIdArgs = {
    customerId?: InputMaybe<Scalars['ID']>;
    statementId: Scalars['ID'];
};

export type QueryStatementsArgs = {
    accountId: Scalars['ID'];
    customerId?: InputMaybe<Scalars['ID']>;
};

export type QueryTransactionArgs = {
    accountId: Scalars['ID'];
    transactionId: Scalars['ID'];
};

export type QueryTransactionsArgs = {
    accountId: Scalars['ID'];
    direction?: InputMaybe<Array<Scalars['String']>>;
    excludeFees?: InputMaybe<Scalars['Boolean']>;
    excludeTaxes?: InputMaybe<Scalars['Boolean']>;
    excludeWithhold?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    since?: InputMaybe<Scalars['String']>;
    sort?: InputMaybe<Scalars['String']>;
    tags?: InputMaybe<TransactionTagsInput>;
    type?: InputMaybe<Array<Scalars['String']>>;
    until?: InputMaybe<Scalars['String']>;
};

export type QueryTransactionsCsvArgs = {
    accountId: Scalars['ID'];
    direction?: InputMaybe<Array<Scalars['String']>>;
    excludeFees?: InputMaybe<Scalars['Boolean']>;
    excludeTaxes?: InputMaybe<Scalars['Boolean']>;
    excludeWithhold?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    month: Scalars['Float'];
    since?: InputMaybe<Scalars['String']>;
    sort?: InputMaybe<Scalars['String']>;
    tags?: InputMaybe<TransactionTagsInput>;
    type?: InputMaybe<Array<Scalars['String']>>;
    until?: InputMaybe<Scalars['String']>;
    year: Scalars['Float'];
};

export type RecurringAchCreditPayment = {
    __typename?: 'RecurringAchCreditPayment';
    /** JSON object representing the payment resource. */
    attributes: RecurringCreditAchPaymentAttributes;
    /** Counterparty of the recurring payment */
    counterparty: Counterparty;
    /** Identifier of the ACH payment resource. */
    id: Scalars['ID'];
    /** Is reccuring payment pending approval. */
    isPendingApproval: Scalars['Boolean'];
    /** Type of the payment resource. */
    type: Scalars['String'];
};

export type RecurringAchCreditPaymentInput = {
    /** Optional, additional payment description (maximum of 80 characters), not all institutions present that. */
    addenda?: InputMaybe<Scalars['String']>;
    /** The amount (cents) of the payment. */
    amount: Scalars['Int'];
    /** Counterparty ID. */
    counterpartyId: Scalars['String'];
    /** Payment description (maximum of 10 characters), also known as Company Entry Description, this will show up on statement of the counterparty. */
    description: Scalars['String'];
    /** The schedule of the recurring payment. */
    schedule: ScheduleInput;
};

export type RecurringCreditAchPaymentAttributes = {
    __typename?: 'RecurringCreditAchPaymentAttributes';
    /** Optional, additional payment description (maximum of 50 characters), not all institutions present that. */
    addenda?: Maybe<Scalars['String']>;
    /** The amount (cents) of the payment. */
    amount: Scalars['Int'];
    /** The date the resource was created. */
    createdAt: Scalars['DateTime'];
    /** Payment description (maximum of 10 characters), also known as Company Entry Description, this will show up on statement of the counterparty. */
    description: Scalars['String'];
    /** Represents the number of payments that were created by this recurring payment. */
    numberOfPayments: Scalars['Int'];
    /** The party on the other side of the ACH payment. */
    schedule: Schedule;
    /** One of Active, Completed, Disabled. */
    status: RecurringPaymentStatus;
    /** The date the resource was updated. */
    updatedAt: Scalars['DateTime'];
};

export enum RecurringPaymentStatus {
    Active = 'Active',
    Completed = 'Completed',
    Disabled = 'Disabled'
}

export type RemoveCounterypartyRelationshipsArgs = {
    /** The counterparty relationships to be removed. */
    counterpartyRelationships: Array<Scalars['String']>;
    customerId?: InputMaybe<Scalars['ID']>;
};

export type SaveUiPreferenceInput = {
    /** The key of the preference. */
    key: Scalars['String'];
    /** The value of the preference. */
    value: Scalars['JSONObject'];
};

export type SavingsDetails = {
    __typename?: 'SavingsDetails';
    /** Total sum of money withheld to non tax buckets */
    bucketsSavings: Scalars['Float'];
    /** Total sum of money withheld to the tax bucket */
    taxSavings: Scalars['Float'];
};

export type Schedule = {
    __typename?: 'Schedule';
    /** Required if interval is Monthly. Scheduled day time in the month. Valid values can be between 1-28 or (-5)-(-1). Negative numbers represent relative day to the end of the month. -1 represents the last day of the month. */
    dayOfMonth?: Maybe<Scalars['Float']>;
    /** Required if interval is Monthly. Required when Weekly interval is specified. Valid values are Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday. */
    dayOfWeek?: Maybe<Scalars['String']>;
    /** RFC3339 format. For more information: https://en.wikipedia.org/wiki/ISO_8601#RFCs End time of the recurring payment. Date only (e.g. "2022-10-29 ") */
    endTime?: Maybe<Scalars['Date']>;
    /** Interval of the schedule. Can be Monthly. */
    interval: Interval;
    /** RFC3339 format. For more information: https://en.wikipedia.org/wiki/ISO_8601#RFCs The next scheduled date of the action. */
    nextScheduledAction?: Maybe<Scalars['String']>;
    /** RFC3339 format. For more information: https://en.wikipedia.org/wiki/ISO_8601#RFCs Start time of the recurring payment. Date only (e.g. "2022-06-29") */
    startTime?: Maybe<Scalars['Date']>;
    /** Optional. Total number of payment repetitions. Positive integers only. */
    totalNumberOfPayments?: Maybe<Scalars['Float']>;
};

export type ScheduleInput = {
    /** Required if interval is Monthly. Scheduled day time in the month. Valid values can be between 1-28 or (-5)-(-1). Negative numbers represent relative day to the end of the month. -1 represents the last day of the month. */
    dayOfMonth?: InputMaybe<Scalars['Float']>;
    /** Required if interval is Weekly. Required when Weekly interval is specified. Valid values are Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday. */
    dayOfWeek?: InputMaybe<DayOfWeek>;
    /** RFC3339 format. For more information: https://en.wikipedia.org/wiki/ISO_8601#RFCs End time of the recurring payment. Date only (e.g. "2022-10-29 ") */
    endTime?: InputMaybe<Scalars['Date']>;
    /** Interval of the schedule. Can be Monthly. */
    interval: Interval;
    /** RFC3339 format. For more information: https://en.wikipedia.org/wiki/ISO_8601#RFCs Start time of the recurring payment. Date only (e.g. "2022-06-29") */
    startTime?: InputMaybe<Scalars['Date']>;
};

export type Statement = {
    __typename?: 'Statement';
    /** JSON object representing the statement data. */
    attributes: StatementAttributes;
    /** Identifier of the statement resource. */
    id: Scalars['ID'];
    /** Type of the statement resource. */
    type: Scalars['String'];
};

export type StatementAttributes = {
    __typename?: 'StatementAttributes';
    /** Period of the statement, formatted YYYY-MM. */
    period: Scalars['String'];
};

export type SubscriptionPaymentMethod = {
    __typename?: 'SubscriptionPaymentMethod';
    cardType: Scalars['String'];
    firstName: Scalars['String'];
    lastFour: Scalars['String'];
    lastName: Scalars['String'];
};

/** The unit of time for the timeframe. */
export enum TimeframeUnit {
    Day = 'Day',
    Month = 'Month',
    Week = 'Week',
    Year = 'Year'
}

export type ToggleRecurringPaymentStatusInput = {
    /** The status of the recurring payment. */
    active: Scalars['Boolean'];
    /** Recurring payment id. */
    recurringPaymentId: Scalars['ID'];
};

export type Token = {
    __typename?: 'Token';
    /** token */
    token: Scalars['String'];
};

export type Transaction = {
    /** The account ID connected to this transaction */
    accountId: Scalars['String'];
    /** The expected completion date of the transaction */
    expectedCompletionDate?: Maybe<Scalars['DateTime']>;
    /** Identifier of the transaction resource. */
    id: Scalars['ID'];
    /** The project connected to this transaction */
    project?: Maybe<Project>;
    /** The recurring payment ID connected to this transaction */
    recurringPaymentId?: Maybe<Scalars['String']>;
    /** Type of the transaction resource. */
    type: Scalars['String'];
    /** A list of the withholdings made from this transaction */
    withholdings?: Maybe<Array<BucketWithhold>>;
};

export type TransactionAttributes = {
    __typename?: 'TransactionAttributes';
    /** The amount (cents) of the transaction. */
    amount: Scalars['Int'];
    /** The account balance (cents) after the transaction. */
    balance: Scalars['Int'];
    /** The category of the transaction */
    category?: Maybe<Scalars['String']>;
    /** The date the transaction was created */
    createdAt: Scalars['DateTime'];
    /** The direction in which the funds flow - Debit or Credit. */
    direction: FundsFlowDirection;
    /** Human-friendly summary of the transaction. */
    summary: Scalars['String'];
    /** The tags of a transaction */
    tags?: Maybe<TransactionTags>;
    /** The title of the transaction */
    title: Scalars['String'];
};

export type TransactionTags = {
    __typename?: 'TransactionTags';
    /** The icon of the bucket at the time of this withholding, only relevant to withholds */
    bucketIcon?: Maybe<Scalars['String']>;
    /** The name of the bucket at the time of this withholding, only relevant to withholds */
    bucketName?: Maybe<Scalars['String']>;
    /** The email of the counterparty of the customer. */
    counterpartyEmail?: Maybe<Scalars['String']>;
    /** The relationship of the counterparty to the customer. */
    counterpartyRelationship?: Maybe<Scalars['String']>;
    /** The total amount withheld to all non-tax buckets */
    otherBucketsWithholdAmount?: Maybe<Scalars['String']>;
    /** The HoneyBook project id connected to this transaction */
    projectId?: Maybe<Scalars['String']>;
    /** The purpose tag of a transaction */
    purpose?: Maybe<Scalars['String']>;
    /** The amount withheld to the tax bucket */
    taxWithholdAmount?: Maybe<Scalars['String']>;
    /** The withhold percentage of the bucket at the time of this withholding, relevant only to withholds */
    withholdPercentage?: Maybe<Scalars['String']>;
};

export type TransactionTagsInput = {
    /** The category tag of a transaction */
    category?: InputMaybe<Scalars['String']>;
    /** The project id tag of a transaction */
    projectId?: InputMaybe<Scalars['String']>;
};

export type UiPreference = {
    __typename?: 'UIPreference';
    /** The key of the preference. */
    key: Scalars['String'];
    /** The value of the preference. */
    value: Scalars['JSONObject'];
};

export enum UnitVerificationTokenChannel {
    Call = 'CALL',
    Sms = 'SMS'
}

export type UpdateAccountAttributes = {
    tags: UpdateAccountTags;
};

export type UpdateAccountInput = {
    accountId: Scalars['ID'];
    attributes: UpdateAccountAttributes;
};

export type UpdateAccountTags = {
    /** The tax withholding value to use. undefined, "smart", or number un range [0-1] */
    taxWithholdingPercentage?: InputMaybe<Scalars['String']>;
};

export type UpdateAchCounterypartyArgs = {
    /** Identifier of the ACH counterparty resource. */
    counterpartyId: Scalars['ID'];
    /** Counterparty tags to update. */
    tags: CounterpartyTagsInput;
};

export type UpdateTransactionInput = {
    /** The ID of the account the transaction belongs to. */
    accountId: Scalars['ID'];
    /** The tags of the transaction */
    tags: TransactionTagsInput;
    /** The ID of the transaction to update. */
    transactionId: Scalars['ID'];
};

/** Represents a yearly revenue goal with progress tracking */
export type YearlyRevenueGoal = Goal & {
    __typename?: 'YearlyRevenueGoal';
    /** The date and time when the goal was created */
    createdAt: Scalars['String'];
    /** Calculates the current progress towards the goal */
    currentProgress: Scalars['Float'];
    /** The end date of the goal period in ISO format (e.g., YYYY-MM-DD) */
    endDate: Scalars['Date'];
    /** Unique identifier for the goal */
    id: Scalars['ID'];
    /** Calculates the progress percentage based on the current progress and goal amount */
    progressPercentage: Scalars['Float'];
    /** The start date of the goal period in ISO format (e.g., YYYY-MM-DD) */
    startDate: Scalars['Date'];
    /** Calculates the current status of the goal */
    status: GoalStatus;
    /** The target amount set for the goal */
    target: Scalars['Float'];
};

export type CashflowQueryVariables = Exact<{
    since: Scalars['DateTime'];
    until: Scalars['DateTime'];
}>;

export type CashflowQuery = {
    __typename?: 'Query';
    cashflow: {
        __typename?: 'Cashflow';
        income: number;
        expenses: number;
        balance: number;
        savings: number;
        incomeDetails: { __typename?: 'IncomeDetails'; clientPayments: number; receivedAchTransaction: number; miscellaneous: number };
        expenseDetails: {
            __typename?: 'ExpenseDetails';
            cardTransaction: number;
            achTransaction: number;
            achPayment: number;
            atmTransaction: number;
            miscellaneous: number;
        };
        savingsDetails: { __typename?: 'SavingsDetails'; taxSavings: number; bucketsSavings: number };
    };
};

export type CreateYearlyRevenueGoalMutationVariables = Exact<{
    data: GoalInput;
}>;

export type CreateYearlyRevenueGoalMutation = {
    __typename?: 'Mutation';
    createYearlyRevenueGoal: {
        __typename?: 'YearlyRevenueGoal';
        id: string;
        target: number;
        startDate: any;
        endDate: any;
        status: GoalStatus;
        currentProgress: number;
        progressPercentage: number;
    };
};

export type GetUploadTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GetUploadTokenQuery = { __typename?: 'Query'; uploadToken: string };

export type AccountNumberQueryVariables = Exact<{
    accountId: Scalars['ID'];
}>;

export type AccountNumberQuery = {
    __typename?: 'Query';
    accountById: { __typename?: 'Account'; attributes: { __typename?: 'AccountAttributes'; accountNumber: string } };
};

export type AccountBalanceQueryVariables = Exact<{
    accountId: Scalars['ID'];
}>;

export type AccountBalanceQuery = {
    __typename?: 'Query';
    accountById: { __typename?: 'Account'; attributes: { __typename?: 'AccountAttributes'; available: number } };
};

export type SavingAccountsBalanceQueryVariables = Exact<{ [key: string]: never }>;

export type SavingAccountsBalanceQuery = {
    __typename?: 'Query';
    savingAccounts: Array<{ __typename?: 'Account'; id: string; attributes: { __typename?: 'AccountAttributes'; balance: number } }>;
};

export type BucketsQueryVariables = Exact<{
    customerId?: InputMaybe<Scalars['ID']>;
    bucketStatus?: InputMaybe<BucketStatus>;
}>;

export type BucketsQuery = {
    __typename?: 'Query';
    buckets: Array<{
        __typename?: 'Bucket';
        id: string;
        isTaxBucket: boolean;
        savings: number;
        name: string;
        savingsPercentage: string;
        icon: string;
        legacySmartTaxRate?: string | null;
    }>;
};

export type UpdateBucketMutationVariables = Exact<{
    bucketId: Scalars['String'];
    savingsPercentage?: InputMaybe<Scalars['String']>;
}>;

export type UpdateBucketMutation = {
    __typename?: 'Mutation';
    updateBucket: {
        __typename?: 'Bucket';
        id: string;
        savings: number;
        name: string;
        savingsPercentage: string;
        isTaxBucket: boolean;
        icon: string;
    };
};

export type UpdateGenericBucketMutationVariables = Exact<{
    bucketId: Scalars['String'];
    savingsPercentage?: InputMaybe<Scalars['String']>;
    bucketName?: InputMaybe<Scalars['String']>;
    bucketIcon?: InputMaybe<Scalars['String']>;
}>;

export type UpdateGenericBucketMutation = {
    __typename?: 'Mutation';
    updateBucket: {
        __typename?: 'Bucket';
        id: string;
        savings: number;
        name: string;
        savingsPercentage: string;
        isTaxBucket: boolean;
        icon: string;
    };
};

export type CloseBucketMutationVariables = Exact<{
    transferBalance?: InputMaybe<Scalars['Boolean']>;
    bucketId: Scalars['String'];
}>;

export type CloseBucketMutation = { __typename?: 'Mutation'; closeBucket: { __typename?: 'Account'; id: string } };

export type CreateSavingsBucketMutationVariables = Exact<{
    customerId?: InputMaybe<Scalars['ID']>;
    bucketName: Scalars['String'];
    bucketSavingsPercentage: Scalars['String'];
    isTaxBucket: Scalars['Boolean'];
    bucketIcon: Scalars['String'];
}>;

export type CreateSavingsBucketMutation = {
    __typename?: 'Mutation';
    createSavingsBucket: {
        __typename?: 'Bucket';
        id: string;
        savings: number;
        name: string;
        savingsPercentage: string;
        isTaxBucket: boolean;
    };
};

export type TransferBucketBalanceMutationVariables = Exact<{
    bucketId: Scalars['String'];
}>;

export type TransferBucketBalanceMutation = {
    __typename?: 'Mutation';
    transferBucketBalance: {
        __typename?: 'Bucket';
        id: string;
        savings: number;
        name: string;
        savingsPercentage: string;
        isTaxBucket: boolean;
    };
};

export type TransferFromBucketBalanceMutationVariables = Exact<{
    bucketId: Scalars['String'];
    amount?: InputMaybe<Scalars['Float']>;
}>;

export type TransferFromBucketBalanceMutation = {
    __typename?: 'Mutation';
    transferFromBucketBalance: {
        __typename?: 'Bucket';
        id: string;
        savings: number;
        name: string;
        savingsPercentage: string;
        isTaxBucket: boolean;
    };
};

export type TransferToBucketBalanceMutationVariables = Exact<{
    bucketId: Scalars['String'];
    amount?: InputMaybe<Scalars['Float']>;
}>;

export type TransferToBucketBalanceMutation = {
    __typename?: 'Mutation';
    transferToBucketBalance: {
        __typename?: 'Bucket';
        id: string;
        savings: number;
        name: string;
        savingsPercentage: string;
        isTaxBucket: boolean;
    };
};

export type HbSubscriptionQueryVariables = Exact<{ [key: string]: never }>;

export type HbSubscriptionQuery = {
    __typename?: 'Query';
    hbSubscription?: {
        __typename?: 'HBSubscription';
        paymentMethod?: { __typename?: 'SubscriptionPaymentMethod'; lastFour: string } | null;
    } | null;
};

export type CreateDebitCardMutationVariables = Exact<{
    data: DebitCardInput;
}>;

export type CreateDebitCardMutation = {
    __typename?: 'Mutation';
    createDebitCard:
        | { __typename?: 'BusinessDebitCard'; id: string }
        | { __typename?: 'BusinessVirtualDebitCard'; id: string }
        | { __typename?: 'IndividualDebitCard'; id: string }
        | { __typename?: 'IndividualVirtualDebitCard'; id: string };
};

export type StatementsQueryVariables = Exact<{
    customerId?: InputMaybe<Scalars['ID']>;
    accountId: Scalars['ID'];
}>;

export type StatementsQuery = {
    __typename?: 'Query';
    statements: Array<{ __typename?: 'Statement'; id: string; attributes: { __typename?: 'StatementAttributes'; period: string } }>;
};

export type GetMonthsWithTransactionsByYearQueryVariables = Exact<{
    accountId: Scalars['ID'];
    year: Scalars['Float'];
}>;

export type GetMonthsWithTransactionsByYearQuery = { __typename?: 'Query'; monthsWithTransactionsByYear: Array<number> };

export type FirstTransactionQueryVariables = Exact<{
    accountId: Scalars['ID'];
    limit?: InputMaybe<Scalars['Int']>;
    sort?: InputMaybe<Scalars['String']>;
}>;

export type FirstTransactionQuery = {
    __typename?: 'Query';
    transactions: Array<
        | { __typename?: 'AchTransaction'; attributes: { __typename?: 'AchTransactionAttributes'; createdAt: any } }
        | { __typename?: 'BaseTransaction'; attributes: { __typename?: 'TransactionAttributes'; createdAt: any } }
        | { __typename?: 'PurchaseTransaction'; attributes: { __typename?: 'PurchaseTransactionAttributes'; createdAt: any } }
    >;
};

export type GetTransactionsCsvQueryVariables = Exact<{
    accountId: Scalars['ID'];
    month: Scalars['Float'];
    year: Scalars['Float'];
}>;

export type GetTransactionsCsvQuery = { __typename?: 'Query'; transactionsCSV: string };

export type GetStatementPdfByIdQueryVariables = Exact<{
    customerId?: InputMaybe<Scalars['ID']>;
    statementId: Scalars['ID'];
}>;

export type GetStatementPdfByIdQuery = { __typename?: 'Query'; statementPDFById: Array<number> };

export type GetStatementHtmlByIdQueryVariables = Exact<{
    customerId?: InputMaybe<Scalars['ID']>;
    statementId: Scalars['ID'];
}>;

export type GetStatementHtmlByIdQuery = { __typename?: 'Query'; statementHTMLById: string };

export type FirstTransactionYearQueryVariables = Exact<{
    accountId: Scalars['ID'];
    limit?: InputMaybe<Scalars['Int']>;
    sort?: InputMaybe<Scalars['String']>;
}>;

export type FirstTransactionYearQuery = {
    __typename?: 'Query';
    transactions: Array<
        | { __typename?: 'AchTransaction'; attributes: { __typename?: 'AchTransactionAttributes'; createdAt: any } }
        | { __typename?: 'BaseTransaction'; attributes: { __typename?: 'TransactionAttributes'; createdAt: any } }
        | { __typename?: 'PurchaseTransaction'; attributes: { __typename?: 'PurchaseTransactionAttributes'; createdAt: any } }
    >;
};

export type AccountTaxWithholdingTagQueryVariables = Exact<{
    accountId: Scalars['ID'];
}>;

export type AccountTaxWithholdingTagQuery = {
    __typename?: 'Query';
    accountById: {
        __typename?: 'Account';
        attributes: {
            __typename?: 'AccountAttributes';
            status: AccountStatusEnum;
            tags: { __typename?: 'AccountTags'; taxWithholdingPercentage?: string | null };
        };
    };
};

export type UpdateAccountMutationVariables = Exact<{
    data: UpdateAccountInput;
}>;

export type UpdateAccountMutation = {
    __typename?: 'Mutation';
    updateAccount: {
        __typename?: 'Account';
        attributes: { __typename?: 'AccountAttributes'; tags: { __typename?: 'AccountTags'; taxWithholdingPercentage?: string | null } };
    };
};

export type CreateBookPaymentMutationVariables = Exact<{
    data: CreateBookPaymentInput;
}>;

export type CreateBookPaymentMutation = {
    __typename?: 'Mutation';
    createBookPayment: {
        __typename?: 'AchPayment';
        attributes: { __typename?: 'AchPaymentAttributes'; status: PaymentStatus; amount?: number | null; description: string };
    };
};

export type ListExpensesQueryVariables = Exact<{
    accountId: Scalars['ID'];
    since?: InputMaybe<Scalars['String']>;
}>;

export type ListExpensesQuery = {
    __typename?: 'Query';
    transactions: Array<
        | {
              __typename?: 'AchTransaction';
              id: string;
              attributes: { __typename?: 'AchTransactionAttributes'; amount: number; category?: string | null };
          }
        | {
              __typename?: 'BaseTransaction';
              id: string;
              attributes: { __typename?: 'TransactionAttributes'; amount: number; category?: string | null };
          }
        | {
              __typename?: 'PurchaseTransaction';
              id: string;
              attributes: { __typename?: 'PurchaseTransactionAttributes'; amount: number; category?: string | null };
          }
    >;
};

export type CreateBusinessApplicationMutationVariables = Exact<{
    data: BusinessApplicationInput;
}>;

export type CreateBusinessApplicationMutation = {
    __typename?: 'Mutation';
    businessApplication: {
        __typename?: 'BusinessApplication';
        id: string;
        attributes: { __typename?: 'BusinessApplicationAttributes'; status: ApplicationStatus };
    };
};

export type CreateIndividualApplicationMutationVariables = Exact<{
    data: IndividualApplicationInput;
}>;

export type CreateIndividualApplicationMutation = {
    __typename?: 'Mutation';
    individualApplication: {
        __typename?: 'IndividualApplication';
        id: string;
        attributes: { __typename?: 'IndividualApplicationAttributes'; status: ApplicationStatus };
    };
};

export type CreateLinkTokenQueryVariables = Exact<{
    plaidProducts: Array<Scalars['String']> | Scalars['String'];
}>;

export type CreateLinkTokenQuery = { __typename?: 'Query'; createLinkToken: { __typename?: 'Token'; token: string } };

export type CreateLinkTokenForReconnectQueryVariables = Exact<{
    institutionId: Scalars['String'];
}>;

export type CreateLinkTokenForReconnectQuery = {
    __typename?: 'Query';
    createLinkTokenForReconnect: { __typename?: 'Token'; token: string };
};

export type MarkItemRepairedMutationVariables = Exact<{
    institutionId: Scalars['String'];
    activeAccountIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type MarkItemRepairedMutation = { __typename?: 'Mutation'; markItemRepaired: boolean };

export type DeletePlaidInstitutionAuthMutationVariables = Exact<{
    institutionId: Scalars['String'];
}>;

export type DeletePlaidInstitutionAuthMutation = { __typename?: 'Mutation'; deletePlaidInstitutionAuth: boolean };

export type ExchangePublicTokenMutationVariables = Exact<{
    token: Scalars['String'];
    institutionId: Scalars['String'];
    institutionName: Scalars['String'];
    activeAccountIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type ExchangePublicTokenMutation = { __typename?: 'Mutation'; exchangePublicToken: boolean };

export type CreateRecurringAchCreditPaymentMutationVariables = Exact<{
    data: RecurringAchCreditPaymentInput;
}>;

export type CreateRecurringAchCreditPaymentMutation = {
    __typename?: 'Mutation';
    createRecurringAchCreditPayment: {
        __typename?: 'RecurringAchCreditPayment';
        id: string;
        type: string;
        isPendingApproval: boolean;
        attributes: {
            __typename?: 'RecurringCreditAchPaymentAttributes';
            createdAt: any;
            updatedAt: any;
            amount: number;
            description: string;
            status: RecurringPaymentStatus;
            numberOfPayments: number;
            addenda?: string | null;
            schedule: {
                __typename?: 'Schedule';
                startTime?: any | null;
                endTime?: any | null;
                interval: Interval;
                dayOfWeek?: string | null;
                dayOfMonth?: number | null;
                totalNumberOfPayments?: number | null;
                nextScheduledAction?: string | null;
            };
        };
        counterparty: {
            __typename?: 'Counterparty';
            routingNumber: string;
            accountNumber: string;
            accountType: AccountType;
            name: string;
            approvalStatus: string;
        };
    };
};

export type ToggleRecurringAchCreditPaymentActivationStatusMutationVariables = Exact<{
    data: ToggleRecurringPaymentStatusInput;
}>;

export type ToggleRecurringAchCreditPaymentActivationStatusMutation = {
    __typename?: 'Mutation';
    toggleRecurringAchCreditPaymentActivationStatus: {
        __typename?: 'RecurringAchCreditPayment';
        id: string;
        type: string;
        attributes: {
            __typename?: 'RecurringCreditAchPaymentAttributes';
            createdAt: any;
            updatedAt: any;
            amount: number;
            description: string;
            status: RecurringPaymentStatus;
            numberOfPayments: number;
            addenda?: string | null;
            schedule: {
                __typename?: 'Schedule';
                startTime?: any | null;
                endTime?: any | null;
                interval: Interval;
                dayOfWeek?: string | null;
                dayOfMonth?: number | null;
                totalNumberOfPayments?: number | null;
                nextScheduledAction?: string | null;
            };
        };
        counterparty: { __typename?: 'Counterparty'; routingNumber: string; accountNumber: string; accountType: AccountType; name: string };
    };
};

export type GetRecurringPaymentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRecurringPaymentsQuery = {
    __typename?: 'Query';
    recurringAchCreditPayments: Array<{
        __typename?: 'RecurringAchCreditPayment';
        id: string;
        isPendingApproval: boolean;
        attributes: {
            __typename?: 'RecurringCreditAchPaymentAttributes';
            status: RecurringPaymentStatus;
            amount: number;
            addenda?: string | null;
            schedule: {
                __typename?: 'Schedule';
                interval: Interval;
                dayOfMonth?: number | null;
                dayOfWeek?: string | null;
                endTime?: any | null;
            };
        };
        counterparty: { __typename?: 'Counterparty'; name: string; approvalStatus: string };
    }>;
};

export type ToggleRecurringPaymentStatusMutationVariables = Exact<{
    recurringPaymentId: Scalars['ID'];
    active: Scalars['Boolean'];
}>;

export type ToggleRecurringPaymentStatusMutation = {
    __typename?: 'Mutation';
    toggleRecurringAchCreditPaymentActivationStatus: {
        __typename?: 'RecurringAchCreditPayment';
        id: string;
        attributes: { __typename?: 'RecurringCreditAchPaymentAttributes'; status: RecurringPaymentStatus };
    };
};

export type BalanceDetailsQueryVariables = Exact<{
    since: Scalars['DateTime'];
    until: Scalars['DateTime'];
    timeframeUnit: TimeframeUnit;
    timezone: Scalars['String'];
    type: Scalars['String'];
}>;

export type BalanceDetailsQuery = {
    __typename?: 'Query';
    balanceDetails?: {
        __typename?: 'Chart';
        datasets: Array<{
            __typename?: 'Dataset';
            label: string;
            data: Array<{ __typename?: 'DataPoint'; count: number; label: string; value: number }>;
        }>;
    } | null;
};

export type HbPaymentsSnapshotQueryVariables = Exact<{
    since: Scalars['Date'];
    until: Scalars['Date'];
    timeframeUnit: TimeframeUnit;
    timezone: Scalars['String'];
}>;

export type HbPaymentsSnapshotQuery = {
    __typename?: 'Query';
    hbPayments?: {
        __typename?: 'Chart';
        datasets: Array<{ __typename?: 'Dataset'; label: string; data: Array<{ __typename?: 'DataPoint'; label: string; value: number }> }>;
    } | null;
};

export type TransferMoneyAccountDetailsQueryVariables = Exact<{
    accountId: Scalars['ID'];
}>;

export type TransferMoneyAccountDetailsQuery = {
    __typename?: 'Query';
    accountById: { __typename?: 'Account'; attributes: { __typename?: 'AccountAttributes'; accountNumber: string; available: number } };
};

export type CreateLinkedPaymentMutationVariables = Exact<{
    data: AchLinkedPaymentInput;
}>;

export type CreateLinkedPaymentMutation = {
    __typename?: 'Mutation';
    createLinkedPayment?: {
        __typename?: 'AchPayment';
        id: string;
        attributes: { __typename?: 'AchPaymentAttributes'; status: PaymentStatus; reason?: string | null };
    } | null;
};

export type AccountLimitsQueryVariables = Exact<{
    accountId: Scalars['ID'];
}>;

export type AccountLimitsQuery = {
    __typename?: 'Query';
    accountById: {
        __typename?: 'Account';
        accountLimits?: {
            __typename?: 'AccountLimits';
            attributes: {
                __typename?: 'AccountLimitsAttributes';
                ach: {
                    __typename?: 'AchLimits';
                    limits: { __typename?: 'AchLimitsAttributes'; dailyCredit: number; monthlyCredit: number };
                    totalsDaily: { __typename?: 'LimitTotalTransfer'; credits: number };
                    totalsMonthly: { __typename?: 'LimitTotalTransfer'; credits: number };
                };
            };
        } | null;
    };
};

export type CreateCounterpartyMutationVariables = Exact<{
    data: AchCounterPartyInput;
}>;

export type CreateCounterpartyMutation = {
    __typename?: 'Mutation';
    createCounterParty: {
        __typename?: 'AchCounterparty';
        id: string;
        type: string;
        attributes: {
            __typename?: 'AchCounterpartyAttributes';
            name: string;
            routingNumber: string;
            accountNumber: string;
            accountType: AccountType;
            type: CounterpartyType;
            tags?: {
                __typename?: 'CounterpartyTags';
                relationship?: string | null;
                email?: string | null;
                approvalStatus?: CounterpartyApprovalStatus | null;
            } | null;
        };
    };
};

export type UpdateCounterpartyMutationVariables = Exact<{
    data: UpdateAchCounterypartyArgs;
}>;

export type UpdateCounterpartyMutation = {
    __typename?: 'Mutation';
    updateCounterparty: {
        __typename?: 'AchCounterparty';
        id: string;
        type: string;
        attributes: {
            __typename?: 'AchCounterpartyAttributes';
            name: string;
            routingNumber: string;
            accountNumber: string;
            accountType: AccountType;
            type: CounterpartyType;
            tags?: { __typename?: 'CounterpartyTags'; relationship?: string | null; email?: string | null } | null;
        };
    };
};

export type DeleteCounterpartyMutationVariables = Exact<{
    data: DeleteAchCounterypartyArgs;
}>;

export type DeleteCounterpartyMutation = { __typename?: 'Mutation'; deleteCounterparty: string };

export type FreezeCardMutationVariables = Exact<{
    data: CardActionInput;
}>;

export type FreezeCardMutation = {
    __typename?: 'Mutation';
    freezeCard:
        | { __typename?: 'BusinessDebitCard'; id: string; attributes: { __typename?: 'CardAttributes'; status: CardStatus } }
        | {
              __typename?: 'BusinessVirtualDebitCard';
              id: string;
              attributes: { __typename?: 'BusinessVirtualDebitCardAttributes'; status: CardStatus };
          }
        | { __typename?: 'IndividualDebitCard'; id: string; attributes: { __typename?: 'CardAttributes'; status: CardStatus } }
        | { __typename?: 'IndividualVirtualDebitCard'; id: string; attributes: { __typename?: 'CardAttributes'; status: CardStatus } };
};

export type UnfreezeCardMutationVariables = Exact<{
    data: CardActionInput;
}>;

export type UnfreezeCardMutation = {
    __typename?: 'Mutation';
    unfreezeCard:
        | { __typename?: 'BusinessDebitCard'; id: string; attributes: { __typename?: 'CardAttributes'; status: CardStatus } }
        | {
              __typename?: 'BusinessVirtualDebitCard';
              id: string;
              attributes: { __typename?: 'BusinessVirtualDebitCardAttributes'; status: CardStatus };
          }
        | { __typename?: 'IndividualDebitCard'; id: string; attributes: { __typename?: 'CardAttributes'; status: CardStatus } }
        | { __typename?: 'IndividualVirtualDebitCard'; id: string; attributes: { __typename?: 'CardAttributes'; status: CardStatus } };
};

export type CloseCardMutationVariables = Exact<{
    data: CardActionInput;
}>;

export type CloseCardMutation = {
    __typename?: 'Mutation';
    closeCard:
        | { __typename?: 'BusinessDebitCard'; id: string; attributes: { __typename?: 'CardAttributes'; status: CardStatus } }
        | {
              __typename?: 'BusinessVirtualDebitCard';
              id: string;
              attributes: { __typename?: 'BusinessVirtualDebitCardAttributes'; status: CardStatus };
          }
        | { __typename?: 'IndividualDebitCard'; id: string; attributes: { __typename?: 'CardAttributes'; status: CardStatus } }
        | { __typename?: 'IndividualVirtualDebitCard'; id: string; attributes: { __typename?: 'CardAttributes'; status: CardStatus } };
};

export type AddCounterpartyRelationshipsMutationVariables = Exact<{
    data: AddCounterypartyRelationshipsArgs;
}>;

export type AddCounterpartyRelationshipsMutation = { __typename?: 'Mutation'; addCounterpartyRelationships: Array<string> };

export type RemoveCounterpartyRelationshipsMutationVariables = Exact<{
    data: RemoveCounterypartyRelationshipsArgs;
}>;

export type RemoveCounterpartyRelationshipsMutation = { __typename?: 'Mutation'; removeCounterpartyRelationships: Array<string> };

export type CounterpartyRelationshipsQueryVariables = Exact<{ [key: string]: never }>;

export type CounterpartyRelationshipsQuery = { __typename?: 'Query'; counterpartyRelationships: Array<string> };

export type CounterPartiesQueryVariables = Exact<{ [key: string]: never }>;

export type CounterPartiesQuery = {
    __typename?: 'Query';
    counterparties: Array<{
        __typename?: 'AchCounterparty';
        id: string;
        type: string;
        attributes: {
            __typename?: 'AchCounterpartyAttributes';
            name: string;
            routingNumber: string;
            accountNumber: string;
            accountType: AccountType;
            type: CounterpartyType;
            tags?: {
                __typename?: 'CounterpartyTags';
                relationship?: string | null;
                email?: string | null;
                approvalStatus?: CounterpartyApprovalStatus | null;
            } | null;
        };
    }>;
};

export type CardTransactionsQueryVariables = Exact<{
    accountId: Scalars['ID'];
    type?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type CardTransactionsQuery = {
    __typename?: 'Query';
    transactions: Array<
        | { __typename?: 'AchTransaction'; id: string }
        | { __typename?: 'BaseTransaction'; id: string }
        | { __typename?: 'PurchaseTransaction'; id: string }
    >;
};

export type CreateTokenVerificationMutationVariables = Exact<{
    customerId?: InputMaybe<Scalars['ID']>;
    channel: UnitVerificationTokenChannel;
}>;

export type CreateTokenVerificationMutation = { __typename?: 'Mutation'; createTokenVerification?: string | null };

export type CreateCustomerTokenMutationVariables = Exact<{
    customerId?: InputMaybe<Scalars['ID']>;
    verificationToken: Scalars['ID'];
    verificationCode: Scalars['ID'];
    purpose: CustomerTokenPurpose;
    fingerprint?: InputMaybe<Scalars['ID']>;
}>;

export type CreateCustomerTokenMutation = {
    __typename?: 'Mutation';
    createCustomerToken?: { __typename?: 'CustomerToken'; isMasked: boolean; token?: string | null } | null;
};

export type GetCustomerPhoneNumberQueryVariables = Exact<{
    customerId?: InputMaybe<Scalars['ID']>;
}>;

export type GetCustomerPhoneNumberQuery = {
    __typename?: 'Query';
    customer?:
        | {
              __typename?: 'BusinessCustomer';
              type: CustomerTypeEnum;
              attributes: {
                  __typename?: 'BusinessCustomerAttributes';
                  contact: { __typename?: 'BusinessContact'; phone: { __typename?: 'Phone'; number: string } };
              };
          }
        | {
              __typename?: 'IndividualCustomer';
              type: CustomerTypeEnum;
              attributes: { __typename?: 'IndividualCustomerAttributes'; phone: { __typename?: 'Phone'; number: string } };
          }
        | null;
};

export type GetExistingCustomerTokenQueryVariables = Exact<{
    purpose: CustomerTokenPurpose;
}>;

export type GetExistingCustomerTokenQuery = {
    __typename?: 'Query';
    getExistingCustomerToken?: { __typename?: 'CustomerToken'; isMasked: boolean; token?: string | null } | null;
};

export type ExternalAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type ExternalAccountsQuery = {
    __typename?: 'Query';
    externalAccounts: Array<{
        __typename?: 'ExternalAccount';
        displayName: string;
        subtype: string;
        institutionName?: string | null;
        institutionId: string;
        currentBalance: number;
        availableBalance: number;
        logo?: string | null;
        lastUpdated?: any | null;
        status: PlaidInstitutionAuthStatus;
    }>;
};

export type HbPaymentsChartQueryVariables = Exact<{
    since: Scalars['Date'];
    until: Scalars['Date'];
    timeframeUnit: TimeframeUnit;
    timezone: Scalars['String'];
}>;

export type HbPaymentsChartQuery = {
    __typename?: 'Query';
    hbPayments?: {
        __typename?: 'Chart';
        datasets: Array<{
            __typename?: 'Dataset';
            label: string;
            data: Array<{ __typename?: 'DataPoint'; count: number; label: string; value: number }>;
        }>;
    } | null;
};

export type ListTransactionsQueryVariables = Exact<{
    accountId: Scalars['ID'];
    excludeTaxes?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    sort?: InputMaybe<Scalars['String']>;
}>;

export type ListTransactionsQuery = {
    __typename?: 'Query';
    transactions: Array<
        | {
              __typename?: 'AchTransaction';
              id: string;
              type: string;
              accountId: string;
              recurringPaymentId?: string | null;
              attributes: {
                  __typename?: 'AchTransactionAttributes';
                  direction: FundsFlowDirection;
                  amount: number;
                  balance: number;
                  summary: string;
                  createdAt: any;
                  category?: string | null;
                  description: string;
                  title: string;
              };
              project?: {
                  __typename?: 'Project';
                  id: string;
                  name: string;
                  workspaceId?: string | null;
                  isDeleted?: boolean | null;
              } | null;
          }
        | {
              __typename?: 'BaseTransaction';
              id: string;
              type: string;
              accountId: string;
              recurringPaymentId?: string | null;
              attributes: {
                  __typename?: 'TransactionAttributes';
                  direction: FundsFlowDirection;
                  amount: number;
                  balance: number;
                  summary: string;
                  createdAt: any;
                  category?: string | null;
                  title: string;
              };
              project?: {
                  __typename?: 'Project';
                  id: string;
                  name: string;
                  workspaceId?: string | null;
                  isDeleted?: boolean | null;
              } | null;
          }
        | {
              __typename?: 'PurchaseTransaction';
              id: string;
              type: string;
              accountId: string;
              recurringPaymentId?: string | null;
              attributes: {
                  __typename?: 'PurchaseTransactionAttributes';
                  direction: FundsFlowDirection;
                  amount: number;
                  balance: number;
                  summary: string;
                  createdAt: any;
                  category?: string | null;
                  title: string;
              };
              project?: {
                  __typename?: 'Project';
                  id: string;
                  name: string;
                  workspaceId?: string | null;
                  isDeleted?: boolean | null;
              } | null;
          }
    >;
};

export type ListAuthorizationsQueryVariables = Exact<{
    accountId: Scalars['ID'];
}>;

export type ListAuthorizationsQuery = {
    __typename?: 'Query';
    authorizations: Array<{
        __typename?: 'Authorization';
        id: string;
        type: string;
        accountId: string;
        attributes: {
            __typename?: 'AuthorizationAttributes';
            amount: number;
            createdAt: any;
            digitalWallet?: DigitalWallet | null;
            paymentMethod?: PaymentMethod | null;
            cardLast4Digits: string;
            merchant: { __typename?: 'Merchant'; name: string; type: number; category: string; location?: string | null };
        };
    }>;
};

export type UpdateExpenseCategoryMutationVariables = Exact<{
    data: UpdateTransactionInput;
}>;

export type UpdateExpenseCategoryMutation = {
    __typename?: 'Mutation';
    updateTransaction:
        | {
              __typename?: 'AchTransaction';
              id: string;
              type: string;
              attributes: { __typename?: 'AchTransactionAttributes'; category?: string | null };
          }
        | {
              __typename?: 'BaseTransaction';
              id: string;
              type: string;
              attributes: { __typename?: 'TransactionAttributes'; category?: string | null };
          }
        | {
              __typename?: 'PurchaseTransaction';
              id: string;
              type: string;
              attributes: { __typename?: 'PurchaseTransactionAttributes'; category?: string | null };
          };
};

export type UpdateExpenseProjectMutationVariables = Exact<{
    data: UpdateTransactionInput;
}>;

export type UpdateExpenseProjectMutation = {
    __typename?: 'Mutation';
    updateTransaction:
        | { __typename?: 'AchTransaction'; id: string; project?: { __typename?: 'Project'; id: string; name: string } | null }
        | { __typename?: 'BaseTransaction'; id: string; project?: { __typename?: 'Project'; id: string; name: string } | null }
        | { __typename?: 'PurchaseTransaction'; id: string; project?: { __typename?: 'Project'; id: string; name: string } | null };
};

export type GetProjectsQueryVariables = Exact<{
    limit?: InputMaybe<Scalars['Float']>;
    query: Scalars['String'];
}>;

export type GetProjectsQuery = { __typename?: 'Query'; search: Array<{ __typename?: 'Project'; id: string; name: string }> };

export type AuthorizationDetailsQueryVariables = Exact<{
    authorizationId: Scalars['ID'];
    customerId: Scalars['ID'];
}>;

export type AuthorizationDetailsQuery = {
    __typename?: 'Query';
    getAuthorization: {
        __typename?: 'Authorization';
        id: string;
        type: string;
        attributes: {
            __typename?: 'AuthorizationAttributes';
            amount: number;
            createdAt: any;
            digitalWallet?: DigitalWallet | null;
            paymentMethod?: PaymentMethod | null;
            cardLast4Digits: string;
            merchant: { __typename?: 'Merchant'; name: string; type: number; category: string; location?: string | null };
        };
    };
};

export type TransactionDetailsQueryVariables = Exact<{
    accountId: Scalars['ID'];
    transactionId: Scalars['ID'];
}>;

export type TransactionDetailsQuery = {
    __typename?: 'Query';
    transaction:
        | {
              __typename?: 'AchTransaction';
              id: string;
              type: string;
              expectedCompletionDate?: any | null;
              attributes: {
                  __typename?: 'AchTransactionAttributes';
                  direction: FundsFlowDirection;
                  amount: number;
                  balance: number;
                  summary: string;
                  createdAt: any;
                  category?: string | null;
                  traceNumber?: string | null;
                  description: string;
                  title: string;
                  counterparty: {
                      __typename?: 'AchTransactionCounterparty';
                      routingNumber: string;
                      accountNumber: string;
                      accountType: string;
                  };
                  tags?: {
                      __typename?: 'TransactionTags';
                      purpose?: string | null;
                      taxWithholdAmount?: string | null;
                      otherBucketsWithholdAmount?: string | null;
                      counterpartyRelationship?: string | null;
                  } | null;
              };
              withholdings?: Array<{
                  __typename?: 'BucketWithhold';
                  withholdAmount?: number | null;
                  bucketName?: string | null;
                  withholdPercentage?: string | null;
                  bucketIcon?: string | null;
              }> | null;
          }
        | {
              __typename?: 'BaseTransaction';
              id: string;
              type: string;
              attributes: {
                  __typename?: 'TransactionAttributes';
                  direction: FundsFlowDirection;
                  amount: number;
                  balance: number;
                  summary: string;
                  createdAt: any;
                  category?: string | null;
                  title: string;
                  tags?: {
                      __typename?: 'TransactionTags';
                      purpose?: string | null;
                      taxWithholdAmount?: string | null;
                      otherBucketsWithholdAmount?: string | null;
                  } | null;
              };
              withholdings?: Array<{
                  __typename?: 'BucketWithhold';
                  withholdAmount?: number | null;
                  bucketName?: string | null;
                  withholdPercentage?: string | null;
                  bucketIcon?: string | null;
              }> | null;
          }
        | {
              __typename?: 'PurchaseTransaction';
              id: string;
              type: string;
              attributes: {
                  __typename?: 'PurchaseTransactionAttributes';
                  direction: FundsFlowDirection;
                  amount: number;
                  balance: number;
                  summary: string;
                  createdAt: any;
                  digitalWallet?: DigitalWallet | null;
                  paymentMethod?: PaymentMethod | null;
                  cardLast4Digits: string;
                  category?: string | null;
                  title: string;
                  merchant: { __typename?: 'Merchant'; name: string; type: number; category: string; location?: string | null };
                  tags?: {
                      __typename?: 'TransactionTags';
                      purpose?: string | null;
                      taxWithholdAmount?: string | null;
                      otherBucketsWithholdAmount?: string | null;
                  } | null;
              };
              withholdings?: Array<{
                  __typename?: 'BucketWithhold';
                  withholdAmount?: number | null;
                  bucketName?: string | null;
                  withholdPercentage?: string | null;
                  bucketIcon?: string | null;
              }> | null;
          };
};

export type GetUiPreferencesQueryVariables = Exact<{ [key: string]: never }>;

export type GetUiPreferencesQuery = {
    __typename?: 'Query';
    getUIPreferences: Array<{ __typename?: 'UIPreference'; key: string; value: any }>;
};

export type SaveUiPreferenceMutationVariables = Exact<{
    input: SaveUiPreferenceInput;
}>;

export type SaveUiPreferenceMutation = {
    __typename?: 'Mutation';
    saveUIPreference: { __typename?: 'UIPreference'; key: string; value: any };
};

export type IndividualApplicationTestMutationVariables = Exact<{
    data: IndividualApplicationInput;
}>;

export type IndividualApplicationTestMutation = {
    __typename?: 'Mutation';
    individualApplication: {
        __typename?: 'IndividualApplication';
        id: string;
        attributes: {
            __typename?: 'IndividualApplicationAttributes';
            status: ApplicationStatus;
            tags: { __typename?: 'ApplicationTags'; memberId: string; hbAccountId: string };
        };
        customer?: { __typename?: 'BusinessCustomer'; id: string } | { __typename?: 'IndividualCustomer'; id: string } | null;
    };
};

export type BusinessApplicationTestMutationVariables = Exact<{
    data: BusinessApplicationInput;
}>;

export type BusinessApplicationTestMutation = {
    __typename?: 'Mutation';
    businessApplication: {
        __typename?: 'BusinessApplication';
        id: string;
        attributes: {
            __typename?: 'BusinessApplicationAttributes';
            status: ApplicationStatus;
            tags: { __typename?: 'ApplicationTags'; memberId: string; hbAccountId: string };
        };
    };
};

export type ListCardsQueryVariables = Exact<{ [key: string]: never }>;

export type ListCardsQuery = {
    __typename?: 'Query';
    cards: Array<
        | { __typename?: 'BusinessDebitCard'; id: string }
        | { __typename?: 'BusinessVirtualDebitCard'; id: string }
        | { __typename?: 'IndividualDebitCard'; id: string }
        | { __typename?: 'IndividualVirtualDebitCard'; id: string }
    >;
};

export type HbSubscriptionTestQueryVariables = Exact<{ [key: string]: never }>;

export type HbSubscriptionTestQuery = {
    __typename?: 'Query';
    hbSubscription?: {
        __typename?: 'HBSubscription';
        paymentMethod?: {
            __typename?: 'SubscriptionPaymentMethod';
            lastFour: string;
            cardType: string;
            firstName: string;
            lastName: string;
        } | null;
    } | null;
};

export type ToggleRecurringAchCreditPaymentActivationStatusTestMutationVariables = Exact<{
    data: ToggleRecurringPaymentStatusInput;
}>;

export type ToggleRecurringAchCreditPaymentActivationStatusTestMutation = {
    __typename?: 'Mutation';
    toggleRecurringAchCreditPaymentActivationStatus: {
        __typename?: 'RecurringAchCreditPayment';
        id: string;
        type: string;
        attributes: {
            __typename?: 'RecurringCreditAchPaymentAttributes';
            createdAt: any;
            updatedAt: any;
            amount: number;
            description: string;
            status: RecurringPaymentStatus;
            numberOfPayments: number;
            addenda?: string | null;
            schedule: {
                __typename?: 'Schedule';
                startTime?: any | null;
                endTime?: any | null;
                interval: Interval;
                dayOfWeek?: string | null;
                dayOfMonth?: number | null;
                totalNumberOfPayments?: number | null;
                nextScheduledAction?: string | null;
            };
        };
    };
};

export type CreateRecurringAchCreditPaymentTestMutationVariables = Exact<{
    data: RecurringAchCreditPaymentInput;
}>;

export type CreateRecurringAchCreditPaymentTestMutation = {
    __typename?: 'Mutation';
    createRecurringAchCreditPayment: {
        __typename?: 'RecurringAchCreditPayment';
        id: string;
        type: string;
        attributes: {
            __typename?: 'RecurringCreditAchPaymentAttributes';
            createdAt: any;
            updatedAt: any;
            amount: number;
            description: string;
            status: RecurringPaymentStatus;
            numberOfPayments: number;
            addenda?: string | null;
            schedule: {
                __typename?: 'Schedule';
                startTime?: any | null;
                endTime?: any | null;
                interval: Interval;
                dayOfWeek?: string | null;
                dayOfMonth?: number | null;
                totalNumberOfPayments?: number | null;
                nextScheduledAction?: string | null;
            };
        };
    };
};

export type ListRecurringAchCreditPaymentsQueryVariables = Exact<{ [key: string]: never }>;

export type ListRecurringAchCreditPaymentsQuery = {
    __typename?: 'Query';
    recurringAchCreditPayments: Array<{
        __typename?: 'RecurringAchCreditPayment';
        id: string;
        isPendingApproval: boolean;
        attributes: { __typename?: 'RecurringCreditAchPaymentAttributes'; amount: number; description: string };
        counterparty: { __typename?: 'Counterparty'; name: string; approvalStatus: string };
    }>;
};

export type ListTransactionsAttackQueryVariables = Exact<{
    accountId: Scalars['ID'];
    excludeTaxes: Scalars['Boolean'];
}>;

export type ListTransactionsAttackQuery = {
    __typename?: 'Query';
    transactions: Array<
        | { __typename?: 'AchTransaction'; id: string; type: string }
        | { __typename?: 'BaseTransaction'; id: string; type: string }
        | { __typename?: 'PurchaseTransaction'; id: string; type: string }
    >;
};

export type ListTransactionsProjectQueryVariables = Exact<{
    accountId: Scalars['ID'];
    excludeTaxes: Scalars['Boolean'];
}>;

export type ListTransactionsProjectQuery = {
    __typename?: 'Query';
    transactions: Array<
        | { __typename?: 'AchTransaction'; id: string; type: string; project?: { __typename?: 'Project'; id: string; name: string } | null }
        | {
              __typename?: 'BaseTransaction';
              id: string;
              type: string;
              project?: { __typename?: 'Project'; id: string; name: string } | null;
          }
        | {
              __typename?: 'PurchaseTransaction';
              id: string;
              type: string;
              project?: { __typename?: 'Project'; id: string; name: string } | null;
          }
    >;
};

export type TransactionDetailsTestQueryVariables = Exact<{
    accountId: Scalars['ID'];
    transactionId: Scalars['ID'];
}>;

export type TransactionDetailsTestQuery = {
    __typename?: 'Query';
    transaction:
        | {
              __typename?: 'AchTransaction';
              id: string;
              type: string;
              attributes: { __typename?: 'AchTransactionAttributes'; traceNumber?: string | null };
          }
        | { __typename?: 'BaseTransaction'; id: string; type: string }
        | { __typename?: 'PurchaseTransaction'; id: string; type: string };
};

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        Application: ['BusinessApplication', 'IndividualApplication'],
        Card: ['BusinessDebitCard', 'BusinessVirtualDebitCard', 'IndividualDebitCard', 'IndividualVirtualDebitCard'],
        Customer: ['BusinessCustomer', 'IndividualCustomer'],
        Goal: ['YearlyRevenueGoal'],
        Transaction: ['AchTransaction', 'BaseTransaction', 'PurchaseTransaction']
    }
};
export default result;
