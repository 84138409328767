import { Card } from '@unit-finance/unit-node-sdk';
import { DateTime } from 'next-auth/providers/kakao';

export { CustomerTokenPurposeEnum } from '@finance/platform';

export const TWO_FACTOR_TEST_CODE = '000001';

export enum AuthPages {
    signIn = '/auth/token',
    signOut = '/auth/signout',
    error = '/auth/hbauth'
}

export type HBUserType = 'regular' | 'admin';

export enum LocalStorageKeys {
    KYC_FORM_VALUES = 'kyc-form-values',
    KYC_LAST_COMPLETED_STEP = 'kyc-last-completed-step',
    SHOW_APPLICATION_SUCCESS = 'show-application-success'
}

export enum PaymentMethod {
    MANUAL = 'Manual',
    SWIPE = 'Swipe',
    CONTACTLESS = 'Contactless',
    CHIP_AND_PIN = 'ChipAndPin',
    STORED = 'Stored',
    OTHER = 'Other'
}

export enum DigitalWallet {
    GOOGLE = 'Google',
    APPLE = 'Apple',
    OTHER = 'Other'
}

export type UnitCustomerTokenScope = 'payments-write' | 'accounts' | 'transactions' | 'cards-sensitive' | 'statements';

export enum CardType {
    IndividualVirtualDebitCard = 'individualVirtualDebitCard',
    BusinessVirtualDebitCard = 'businessVirtualDebitCard',
    IndividualPhysicalDebitCard = 'individualDebitCard',
    businessPhysicalDebitCard = 'businessDebitCard'
}

export interface IHBSessionData {
    hbApiVersion: number;
    hbToken: string;
}

export type PermissionName = 'funds';

export type Permission = {
    name: PermissionName;
};

export enum FeatureFlags {
    ExpenseCategorizationPushNotification = 'expense_categorization_push',
    UploadDocumentsImprovements = 'finance_upload_documents_improvements',
    FinanceAppOverview = 'finance_app_overview',
    FinanceRecurringTransfer = 'finance_recurring_transfer',
    BankMigration = 'finance_bank_migration',
    FinanceMigrationErrorBanner = 'finance_bank_migration_error_banner',
    FinanceOverviewCardsDetailsModal = 'finance_overview_cards_details_modal',
    FinanceSavingBalanceHeader = 'finance_saving_balance_header',
    FinanceNewCashflowWidget = 'finance_new_cashflow_widget',
    FinanceHBFunds = 'finance_app',
    FinanceNewWelcomePage = 'finance_new_welcome_page',
    FinanceHBFundsKyc = 'hb_funds_kyc_kill_switch',
    FinancePermissionsAuth = 'finance_permissions_auth',
    FinanceOverviewPromoteHbfEducationVideo = 'finance_overview_promote_hbf_education_video',
    FinanceMWebOptimization = 'finance_mweb_optimization',
    YearlyRevenueGoal = 'finance_yearly_revenue_goal'
}

export enum OfficerTitle {
    CEO = 'CEO',
    COO = 'COO',
    CFO = 'CFO',
    President = 'President',
    BenefitsAdministrationOfficer = 'BenefitsAdministrationOfficer',
    CIO = 'CIO',
    VP = 'VP',
    AVP = 'AVP',
    Treasurer = 'Treasurer',
    Secretary = 'Secretary',
    Controller = 'Controller',
    Manager = 'Manager',
    Partner = 'Partner',
    Member = 'Member'
}

export enum EntityType {
    Corporation = 'Corporation',
    LLC = 'LLC',
    Partnership = 'Partnership',
    SoleProprietor = 'Sole proprietor'
}

export enum UnitVerificationTokenChannel {
    SMS = 'SMS',
    CALL = 'CALL'
}

export enum CardStatus {
    Active = 'Active',
    Inactive = 'Inactive',
    Stolen = 'Stolen',
    Lost = 'Lost',
    Frozen = 'Frozen',
    ClosedByCustomer = 'ClosedByCustomer',
    SuspectedFraud = 'SuspectedFraud'
}
export type TCardStatus = keyof typeof CardStatus;

export interface CardData {
    id: string;
    last4Digits: string;
    fullName: FullName;
    expirationDate: string;
    status: TCardStatus;
    type: Card['type'];
}

export interface FullName {
    first: string;
    last: string;
}

export type DocumentMimeType = 'image/png' | 'image/jpeg' | 'application/pdf';

export type TAccountTX = {
    transactionId: string;
    accountId: string;
};

export type TimeRange = {
    since: string;
    until: string;
};

export type Change<T> = {
    new: T;
    old: T;
};

export type Bucket = {
    id: string;
    savings: number;
    name: string;
    savingsPercentage: string;
    isTaxBucket: boolean;
    icon: string;
    legacySmartTaxRate?: string;
};

export interface IHoneybookPayment {
    payment_amount: number;
    payment_due_date: string;
}

export enum Timeframe {
    MonthToDate = 'monthToDate',
    QuarterToDate = 'quarterToDate',
    YearToDate = 'yearToDate',
    ThisMonth = 'thisMonth',
    NextMonth = 'nextMonth',
    NextThreeMonths = 'nextThreeMonths',
    ThisYear = 'thisYear'
}

export enum TimeframeUnit {
    Month = 'month',
    Week = 'week',
    Day = 'day',
    Year = 'year'
}

export type BasicExternalAccount = {
    accountMask: string;
    subtype: string;
};

// external account as received from flitter.
export type ExternalAccountData = BasicExternalAccount & {
    officialName: string;
    type: string;
    institutionName: string;
    institutionId: string;
    accountId: string;
    accountName: string;
    currentBalance: number;
    availableBalance: number;
    lastUpdated: DateTime;
};

export type BalanceData = {
    date: Date;
    balance: number;
};

// external account balance details as received from flitter.
export type ExternalAccountBalanceDetailsData = BasicExternalAccount & {
    subtype: string;
    institutionId: string;
    accountId: string;
    accountMask: string;
    balanceData: BalanceData[];
};

export enum LogLevel {
    Info = 'info',
    Debug = 'debug',
    Warning = 'warn',
    Error = 'error'
}
